import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.css";
import ReactGA from "react-ga";
const TRACKING_ID_ADWORDS = "AW-11041977645"; // OUR_TRACKING_ID google analytics
const TRACKING_ID_ANALYTICS = "G-R6CKSDXW66";
//ReactGA.initialize(TRACKING_ID);
ReactGA.initialize(
  {
    trackingId: TRACKING_ID_ADWORDS,
    gaOptions: { name: "adwords" },
  },
  {
    trackingId: TRACKING_ID_ANALYTICS,
    gaOptions: { name: "analytics" },
  }
);
ReactGA.pageview(window.location.pathname + window.location.search);
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
