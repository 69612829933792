const data = {
  team: [
    {
      image: "/images/team/HDSHT - Peter Belcastro - 300x300.webp",
      linkPath: "/about/team/peter_belcastro",
      name: "Pete Belcastro",
    },
    {
      image: "/images/team/HDSHT - Ivan Raya - B-W - 300x300.webp",
      linkPath: "/about/team/ivan_raya",
      name: "Ivan Raya",
    },
    {
      image: "/images/team/HDSHT - MJB - B-W - 300x300.webp",
      linkPath: "/about/team/michael_j_brown",
      name: "Michael J. Brown",
    },
    {
      image: "/images/team/HDSHT - Lawrence Pitkin - B-W - 300x300.webp",
      linkPath: "/about/team/lawrence_pitkin",
      name: "Lawrence Pitkin",
    },
    {
      image: "/images/team/HDSHT - Jaime_Garza - B-W - 300x300.webp",
      linkPath: "/about/team/jaime_garza",
      name: "Jaime Garza",
    },
    {
      image: "/images/team/HDSHT - Jarrod_Kadonaga - B-W - 300x300.webp",
      linkPath: "/about/team/jarrod_kadonaga",
      name: "Jarrod Kadonaga",
    },
    {
      image: "/images/team/HDSHT - Jen_Tani - B-W - 300x300.webp",
      linkPath: "/about/team/jennifer_tani",
      name: "Jennifer Tani",
    },
    {
      image: "/images/team/HDSHT - Chris Acuesta - B-W - 300x300.webp",
      linkPath: "/about/team/chris_acuesta",
      name: "Chris Acuesta",
    },
    {
      image: "/images/team/HDSHT - Danelle - B-W - 300x300_2.webp",
      linkPath: "/about/team/danelle_skinner",
      name: "Danelle Skinner",
    },
    {
      image: "/images/team/HDSHT - Chris Irving - B-W - 300x300.webp",
      linkPath: "/about/team/chris_irving",
      name: "Chris Irving",
    },
    {
      image: "/images/team/HDSHT - Wagdy Elagamy - 2 - 600x600.jpeg",
      linkPath: "/about/team/wagdy_elagamy",
      name: "Wagdy Elagamy",
    },
    // new  Mar 5th
    {
      image: "/images/team/HDSHT - Sondra Curran - 600x600.jpeg",
      linkPath: "/about/team/sondra_curran",
      name: "Sondra Curran",
    },
    {
      image: "/images/team/HDSHT - Ericka Tutino - 600x600.jpeg",
      linkPath: "/about/team/erika_tutino",
      name: "Ericka Tutino",
    },
  ],
};
//team details
const members = [
  {
    name: "Peter Belcastro",
    title: "FOUNDER | OWNER | CEO",
    image: "/images/team/HDSHT - Peter Belcastro - 600x600.webp",
    education: [
      {
        school: "University of California, Los Angeles",
        date: "June 2000",
        degree: "B.A. | Philosophy",
      },
      {
        school: "NAVAL CONSTRUCTION TRAINING CENTER – Port Hueneme, CA",
        date: "June 2010",
        degree: "Certifed Planner and Estimator",
      },
      {
        school: "University of California, Los Angeles",
        date: "June 2015",
        degree: "M.A. | Interior Architecture",
      },
    ],
    licenses: [
      {
        license: "STATE OF CALIFORNIA CONTRACTORS STATE LICENSE BOARD",
      },
      { license: "- Licensed General Contractor #975354" },
    ],
    certifications: [{ cert: "OSHA 30 Hour" }],
    about:
      "A results-oriented, hands-on construction professional, with over 14 years of expertise in all facets of the industry.  A verifiable track record for the successful completion of several construction projects through coordinating trades, developing partnerships, and building positive rapport with engineers, inspectors and clients while maintaining costs.  Versed in project planning and estimating, impending design problems, document preparation, building code and regulations, and site management through certification of occupancy. Mr. Belcastro served as the Lead Senior Project Manager for Delta Air Lines $1.78 billion Sky Way Project, where he helped oversee the day-to-day management of the project and was also responsible for the project closeout of Delta’s $270 million relocation project.  He was the project manager and superintendent from 2014 thru 2017 on the $392 million United Airlines Terminal Renovation Project and $66 million LAX Westfield Terminal 2 Project. He served as a member of the United States Naval Construction Force from 2007 to 2015.  Mr. Belcastro deployed to Africa, Afghanistan, and Europe where he served as the construction manager for several projects and received the Navy and Marine Corps Achievement Medal for each deployment. ",
  },

  {
    name: "Ivan Raya",
    title: "SENIOR CONSTRUCTION MANAGER",
    image: "/images/team/HDSHT - Ivan Raya - 449x449.webp",
    education: [
      {
        school: "University of California, Los Angeles",
        date: "June 2004",
        degree: "B.S. | Mathematics Applied Science in Astrophysics",
      },
    ],
    licenses: null,
    certifications: [{ cert: "OSHA 30 Hour" }],
    about:
      "Mr. Raya serves as Senior Construction Manager for Belcastro Construction Management, responding to all requirements and concerns of the Client. He has extensive experience in developing and monitoring project master schedules, estimating, job cost reports, and establishing and implementing efective communication procedures for all team components.  Recognized in the industry for his ability to isolate and resolve various design and compliance issues early in a project’s evolution, his aptitude and expertise are evidenced in the many successful projects with which he has been involved. His project experience covers public and private clients under a wide variety of project delivery methods.  Mr. Raya is also highly skilled at supervising and facilitating the work activities of all trades on dynamic construction jobsites; coordinating work activities and logistics plans with construction crews and project stakeholders.",
  },

  {
    name: "Michael J. Brown",
    title: "CONSTRUCTION MANAGER | STRUCTURAL ENGINEER",
    image: "/images/team/HDSHT - MJB - 600x600.webp",
    education: [
      {
        school: "California Polytechnic State University, San Luis Obispo",
        date: "June 2014",
        degree:
          "M.S. | Architecture - Specialization in Archistectural Engineering Graduated with Honors",
      },
      {
        school: "California Polytechnic State University, San Luis Obispo",
        date: "June 2007",
        degree: "B.S. | Architectural Engineering",
      },
    ],
    licenses: [
      {
        license:
          "Licensed in California by the Board of Professional Engineers and Land Surveyors:",
      },
      { license: "- Structural Engineer in April 2017. License #6519" },
      { license: "- Civil Engineer in June 2011. License #78403" },
      { license: "" },
      { license: "STATE OF CALIFORNIA CONTRACTORS STATE LICENSE BOARD" },
      { license: "- Licensed General Contractor #975354" },
      { license: "" },
    ],
    certifications: [{ cert: "OSHA 30 Hour" }],
    about:
      "An experienced structural engineer and construction manager with over 13 years of success analyzing, designing, and detailing: Steel, Concrete, Masonry, and Light Gauge Metal Structures use for Residential, Office, Hospitality, Mixed-Use, and Education purposes. Worked directly with project manager and/or partner, aiding with coordination efforts and mentoring Staff Engineers. Leader of Construction Administration efforts by attending OAC meetings, conducting shop drawing reviews, providing RFI solutions, conductiong structural site observations, and preparing site visit reports. Lead Coordination between Owner, Design Team, and CMAR for construction logistics, Design review and interface with LAWA team.",
  },

  {
    name: "Lawrence Pitkin",
    title: "SENIOR CONSTRUCTION & DESIGN MANAGER",
    image: "/images/team/HDSHT - Lawrence Pitkin - 600x600.webp",
    education: [
      {
        school: "University of Southern California",
        date: "2013",
        degree:
          "M.S. | Construction Management - Area of focus in Real Estate Development",
      },
      {
        school: "University of California, Los Angeles",
        date: "June 2008",
        degree:
          "M.S. | Civil Engineering - Area of focus in Earthquake Engineering",
      },
      {
        school: "University of California, San Diego",
        date: "December 2004",
        degree: "B.S. | Structural Engineering",
      },
    ],
    licenses: [
      {
        license:
          "Licensed in California by the Board of Professional Engineers and Land Surveyors:",
      },
      { license: "- Structural Engineer in October 2010. License #5567" },
      { license: "- Civil Engineer in April 2007. License #71571" },
    ],
    certifications: [
      { cert: "Design Build Institute of America Certifcation" },
      { cert: "OSHA 30 Hour" },
    ],
    about:
      "A Los Angeles native Structural Engineer and Construction Manager with over 15 years of experience in the engineering and construction fields. Successfully manages a diverse array of structural design and construction projects, including aviation, hospitals, schools, and retrofits. Possesses a unique understanding for the project goals, requirements and work processes that facilitate successful realization of projects.",
  },

  {
    name: "Jaime Garza",
    title: "SENIOR DESIGN MANAGER",
    image: "/images/team/HDSHT - Jaime_Garza - 600x600.webp",
    education: [
      {
        school: "University of California, Berkeley",
        date: "June 2003",
        degree: "M.S. | Structural Engineering, Mechanics, and Materials",
      },
      {
        school: "The University of Texas, Austin",
        date: "June 2002",
        degree: "B.S. | Architectural Engineering",
      },
    ],
    licenses: [
      {
        license:
          "Licensed in California by the Board of Professional Engineers and Land Surveyors:",
      },
      { license: "- Structural Engineer in January, 2010. License #5456" },
      { license: "- Civil Engineer in January, 2005. License #68079" },
      { license: "" },
      {
        license:
          "Licensed in Arizona by the Board of Professional Engineers and Land Surveyors:",
      },
      { license: "- Structural Engineer in November, 2010. License #51873" },
    ],
    certifications: [{ cert: "OSHA 30 Hour" }],
    about:
      "Jaime Garza has over 16 years of experience in structural and earthquake engineering design. She has led the structural design for projects in Education, Civic, Aviation, Residential, Hospitality, Fine Arts, Performing Arts, Healthcare, and Commercial sectors. Jaime has utilized performance-based engineering for the design of new buildings, the retrofit of existing buildings, and the evaluation of existing building for over 40 buildings. Jaime led the innovative retrofit of the LAX Theme Building using a mass damper on top of the building to counteract earthquake shaking. Managing the structural design and collaborated closely with the architects and contractor for the new Waldorf Astoria Hotel in Beverly Hills. Jaime also managed the design coordination and structural design for four 10-story precast student housing towers at Stanford University, adding over 2,400 beds for graduate housing.  Jaime recently co-authored the paper “Should We Rely on Existing Building Lateral Systems when Retrofitting Buildings?” and presented the findings during the 2018 Structural Engineering Association of California Convention. The research provides an overview of our building codes and analysis tools, as well as a complete picture of what earthquake resiliency requires. Jaime is committed to collaborating with clients and architects to truly implement resilient structural design.",
  },

  {
    name: "Jarrod Kadonaga",
    title: "PURCHASING & INSIDE SALES MANAGER",
    image: "/images/team/HDSHT - Jarrod_Kadonaga - 600x600.webp",
    education: null,
    certifications: null,
    about:
      "General Manager, CB Wholesale Electric. Jarrod Kadonaga serves as the General Manager of CB Wholesale Electric, where he oversees sales and purchasing for the company. Prior to joining CBWE, Mr. Kadonaga spent 25 years in the electrical contracting/distribution industry where he served in a variety of positions from inside sales rep to operations manager. Jarrod enjoys fishing with his nephews and competitively racing RC cars.",
  },

  {
    name: "Jennifer Tani",
    title: "OFFICE MANAGER & EXECUTIVE ASSISTANT",
    image: "/images/team/HDSHT - Jen_Tani - 600x600.webp",
    education: null,
    certifications: null,
    about:
      "Jen is from North Hollywood, CA and she attended Pierce College. Jen has been serving customers happily since joining the workforce for many years. She enjoys playing basketball, shopping, and cooking. Her favorite movie is 'Just Go With It'. Jen reallys enjoys traveling and her favorite vacation spot is Maui, Hawaii.",
  },

  {
    name: "Chris Acuesta",
    title: "WHAREHOUSE & LOGISTICS MANAGER",
    image: "/images/team/HDSHT - Chris Acuesta - 600x600.webp",
    education: null,
    certifications: null,
    about:
      "Chris manages our Logistics Warehouse. He is from Torrance, CA and has been in the electrical contractor industry for 35 years; including stints in logistics for over 15 years. He has an additional 5 years of managing inventory and various warehousing projects. He is organized, thorough, and ready to help any customer with all of their needs. Chris will be happy to take care of your delivery, log the items into the digital warehouse for you to view your inventory/stock on site whenever you need, and manage the delivery of said item to the site when you need it. Chris enjoys the John Wick movies, and likes to vacation in Hawaii.",
  },

  {
    name: "Danelle Skinner",
    title: "TRANSPORTATION MANAGER",
    image: "/images/team/HDSHT - Danelle - 600x600_2.webp",
    education: null,
    certifications: null,
    about:
      "Danelle was in the Construction Batallion for the U.S. Navy. He served in the Navy for 6 years and the SEAbees for 3 years including tours in Afganistan, Iraq, and Kuwait as part of Operation Enduring Freedom. In fact, Danelle was on a tour to Uganda the rotation before our founder Pete Belcastro was on the same tour. So they almost met years before Danelle joined our team. Danelle was honorably discharged as an Petty Officer Third Class in 2011. He has a CDL class A license and currently manages our entire fleet of rental equipment, including driving the Peterbilt Semi and trailer for our heavy equipment delivery.",
  },

  {
    name: "Chris Irving",
    title: "WHAREHOUSE & LOGISTICS ASSOCIATE",
    image: "/images/team/HDSHT - Chris Irving - 600x600.webp",
    education: null,
    certifications: null,
    about:
      "Chris is from Oakland, CA. Chris is an integral part of our Logistics division handling the transportation and organization of the materials stored in our warehouse. When not working at our facility he enjoys Fishing, Fantasy Football, cooking, and exploring the outdoors. His favorite place to vacation is Cancun, Mexico.",
  },

  {
    name: "Wagdy Elagamy",
    title: "ACCOUNTING MANAGER",
    image: "/images/team/HDSHT - Wagdy Elagamy - 2 - 600x600.jpeg",
    education: [
      {
        school: "Alexandria University , Alexandria Egypt",
        date: "Feburary 2002",
        degree:
          "B.COM | Business and Finance U.S. Equivalent: Bachelor’s degree, Evaluated by WES reference #: 5096591/mfb.",
      },
      {
        school:
          "IMA (Institute of Management Accountants) Member #000010673335",
      },
    ],
    licenses: [
      {
        license:
          "Registered TAX PREPARER State of California CTEC ID # A335484",
      },
    ],
    certifications: [
      {
        cert: "CTEC Qualifying Education 60 Hour November 21, 2022",
      },
    ],
    about:
      "An accounting Specialist with over twenty years of US and International experience in the preparation, analysis, and reporting of financial performance in large engineering, manufacturing and transportation companies. Proficient in management of accounts receivable, accounts payable, financial reporting and account reconciliation. Dedicated to accuracy, financial control, and performance excellence. Wagdy joined Belcastro group in June 2022; his favorite movie is Braveheart, and he enjoys vacationing in Hawaii.",
  },
  {
    name: "Sondra Curran",
    title: "ACCOUNT MANAGER | AYARD",
    image: "/images/team/HDSHT - Sondra Curran - 600x600.jpeg",
    education: [
      {
        school: "Portland State University, Portland, OR",
        date: "June 2022",
        degree: "B. S. | Public Health",
      },
    ],
    certifications: null,
    about:
      "Sondra served in the Naval construction battalion- nmcb 3, for 5 years (2014-2019). She was honorably discharged as an E4 (petty officer third class). She was deployed to Southeast Asia on a humanitarian mission in Cambodia, South Korea, and Japan. Sondra has 2 dogs 'who my world revolves around'. She also enjoys  hiking, road trips, and snow sports.",
  },
  {
    name: "Erika Tutino",
    title: "BUSINESS DEVELOPMENT MANAGER",
    image: "/images/team/HDSHT - Ericka Tutino - 600x600.jpeg",
    education: null,
    certifications: null,
    about:
      "Ericka is a US Navy Veteran who served, active duty, for 5 years as a Steel Worker in Construction Battalion 5. Her service included 3 desert tours with her first 2 tours in Afghanistan and her last in Africa. During her second tour to Afghanistan, she was assigned to work with our founder, Pete Belcastro, on construction projects that positively impacted the US Navy’s mission during Operation Enduring Freedom. Ericka’s dedication to Operation Enduring Freedom earned her a Navy Achievement Medal and the opportunity to attend the Naval Construction Center’s Planning and Estimating program which led to her leading P&E efforts in over 6 contingency construction projects in Africa. She was also involved with the first of its kind military to military construction operations in Africa which earned her an Army Achievement Medal. In December 2012, Ericka was honorably discharged from the Navy as a Petty Officer Second Class. Ericka is a people person who embodies a “can do” spirit to get the job done.",
  },
];

module.exports = {
  data,
  members,
};
