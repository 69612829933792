import { Fragment, useEffect } from "react";
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import Navbar from "../About/Nav/aboutNavbar";
import Footer from "../../components/Footer/index";

import "../About/about.css";

const Career = () => {
  const history = useHistory();

  const navHandler = (selectedKey) => {
    if (!selectedKey) {
      history.push("/");
    } else {
      history.push(`${selectedKey}`);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Fragment>
      <div className="pageContainer">
        <main className="about_container">
          <Navbar />
          <div className="whoweare_hero">
            <img
              src="/images/careers/CAREERS - Banner - 1280x240.webp"
              alt="Careers at The Belcastro Group Companies"
            />
            <div className="whoweare_hero-box career_wwa">
              <h1>CAREERS</h1>
            </div>
          </div>
          <div className="career_text-p">
            At the Belcastro Construction Group, we offer the opportunity to
            collaborate with talented individuals who strive for high-quality
            results, while maintaining Symbiotic business partnerships. With
            many exciting positions available across the varied facets of our
            companies, we invite you to explore a great opportunity with a
            purpose-driven company.
          </div>
          <div className="mission_statement">
            <p style={{ color: "#145DA0", fontSize: "2.2rem" }}>
              the{" "}
              <span
                style={{
                  color: "#2c4c6c",
                  fontSize: "3.5rem",
                  fontWeight: "bold",
                }}
              >
                BELCASTRO
              </span>{" "}
              way
            </p>
            <p>
              THE DIFFICULT WE DO IMMEDIATELY; THE IMPOSSIBLE TAKES A LITTLE
              LONGER
            </p>
          </div>
          <navigation className="career_logo">
            <div className="career_logo-box border">
              <a href="/companies/cb-wholesale" alt="CB Wholesale page">
                <img
                  src="/images/logos/LOGO - CB Wholesale - white background - square - 360x360.webp"
                  alt="CB Wholesale logo"
                />
              </a>
            </div>
            <div className="career_logo-box">
              <div className="career_logo-circle_b"></div>
            </div>
            <div className="career_logo-box">
              <a href="/companies/cb-design" alt="CB Design page">
                <img
                  src="/images/logos/LOGO - CB-design - square - 320x320.webp"
                  alt="CB Design logo"
                />
              </a>
            </div>
            <div className="career_logo-box">
              <div className="career_logo-circle_y"></div>
            </div>
            <div className="career_logo-box">
              <a href="/companies/ayard-rentals" alt="Ayard Rentals page">
                <img
                  src="/images/logos/LOGO - AYARD - 360x360.webp"
                  alt="Ayard Rentals logo"
                />
              </a>
            </div>
            <div className="career_logo-box">
              <div className="career_logo-circle_b"></div>
            </div>
            <div className="career_logo-box">
              <a href="/companies/cb-logistics" alt="CB Logistics page">
                <img
                  src="/images/logos/LOGO - CB Logistics - square - 360x360.webp"
                  alt="CB Logistics logo"
                />
              </a>
            </div>
            <div className="career_logo-box">
              <div className="career_logo-circle_y"></div>
            </div>
            <div className="career_logo-box border">
              <a
                href="/companies/belcastro-construction-management"
                alt="Belcastro Construction Management page"
              >
                <img
                  src="/images/logos/LOGO - BCM honey comb - square - 320x320.webp"
                  alt="Becalso Construction Management logos"
                />
              </a>
            </div>
          </navigation>

          <div>
            <Footer navHandler={navHandler} />
          </div>
        </main>
      </div>
    </Fragment>
  );
};

export default withRouter(Career);
