import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import "./style.css";
function Footer(props) {
  const navHandler = props ? props.navHandler : {};
  const { mobileScreen } = props;
  const handleKeyPress = (event, path) => {
    if (event.key === "Enter" || event.key === " ") {
      if (navHandler) navHandler(path);
    }
  };
  return (
    <div className="footer " role="navigation" aria-label="Main">
      <div
        className="footerNavWrapper footer__logo"
        role="button"
        type="button"
        onClick={() => {
          if (navHandler) navHandler("/");
        }}
        onKeyPress={(e) => {
          handleKeyPress(e, "/");
        }}
      >
        {/* The Belcastro Group */}
        <a href="/">
          <img
            className="footer__logo-img"
            src="/images/logos/LOGO - The Belcastro Group - 672x91.webp"
            alt="The Belcastro Group logo"
            border="0"
          />
        </a>
      </div>
      <div className=" footer_nav d-none d-sm-flex" role="button" type="button">
        <div
          className="footerNavItem"
          onClick={() => {
            if (navHandler) navHandler("/");
          }}
          onKeyPress={(e) => {
            handleKeyPress(e, "/");
          }}
        >
          Home
        </div>
        <div className="divider"></div>
        <div
          className="footerNavItem"
          type="button"
          onClick={() => {
            if (navHandler) navHandler("/about");
          }}
          onKeyPress={(e) => {
            handleKeyPress(e, "/about");
          }}
          role="button"
        >
          {mobileScreen ? "Who" : "Who We Are"}
        </div>
        <div className="divider"></div>
        <div
          role="button"
          type="button"
          className="footerNavItem"
          onClick={() => {
            if (navHandler) navHandler("/veteran_outreach");
          }}
          onKeyPress={(e) => {
            handleKeyPress(e, "/veteran_outreach");
          }}
        >
          {mobileScreen ? "Veterans" : "Veteran Outreach"}
        </div>
        <div className="divider"></div>
        {/* <div className="footerNavItem" onClick={()=>props.navHandler('/our-companies')}>Our Companies</div>
       <div className="divider"></div> */}
        <div
          role="button"
          type="button"
          className="footerNavItem"
          onClick={() => {
            if (navHandler) navHandler("/career");
          }}
          onKeyPress={(e) => {
            handleKeyPress(e, "/career");
          }}
        >
          Careers
        </div>
        <div className="divider"></div>
        <Link
          role="button"
          type="button"
          to={{
            pathname: "https://belcastrogroup.blog/blog",
          }}
          target="_blank"
          style={{ textDecoration: "none" }}
        >
          <div
            type="button"
            role="button"
            className="footerNavItem"
            style={{ textDecoration: "none" }}
          >
            Blog
          </div>
        </Link>

        {/* <div
          className="footerNavItem"
          onClick={() => {
            if (navHandler) navHandler("/press");
          }}
        >
          Press
        </div> */}
        <div className="divider mx-2"></div>

        <div
          role="button"
          type="button"
          className="footerNavItem"
          onClick={() => {
            if (navHandler) navHandler("/contact");
          }}
          onKeyPress={(e) => {
            handleKeyPress(e, "/contact");
          }}
        >
          Contact
        </div>
      </div>
    </div>
  );
}
Footer.propTypes = {
  children: PropTypes.any,
  navHandler: PropTypes.func,
  mobileScreen: PropTypes.any,
};
export default Footer;
