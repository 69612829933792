import React, { useState } from "react";
import PropTypes from "prop-types";
Slide.propTypes = {
  hoverCard: PropTypes.number,
  hoverCaption: PropTypes.string,
  hoverCaptionClass: PropTypes.string,
  //   hoverCaptionText: PropTypes.string,
  imgUrls: PropTypes.array,
  hoverClass: PropTypes.string,
  hoverCardHeight: PropTypes.number,
};
function Slide(props) {
  const {
    id,
    hoverCard,
    hoverCaption,
    hoverCaptionClass,
    // hoverCaptionText,
    imgUrls,
    hoverClass,
    hoverCardHeight,
  } = props;
  const [slideState, setSlideState] = useState(false);
  function MouseOver(event) {
    setSlideState(true);
    // /event.target.style.background = 'red';
    console.log(event);
  }
  function MouseOut(event) {
    setSlideState(false);
    event.target.style.background = "";
  }
  return (
    <div
      className="slides"
      id={`slides${id}`}
      style={{}}
      onMouseEnter={MouseOver}
      onMouseLeave={MouseOut}
    >
      {/* small image */}
      <div
        className={slideState ? `${hoverClass} ` : `${hoverCard}`}
        style={slideState ? { zIndex: 11111 } : {}}
      >
        {imgUrls && hoverCard === "hoverCardBottom" ? (
          <img
            style={{
              width: "100%",
              height: hoverCardHeight,
              marginTop: -hoverCardHeight,
            }}
            alt="image that slides over the background image from the bottom"
            src={imgUrls[1]}
          />
        ) : (
          ""
        )}
        {imgUrls && hoverCard === "hoverCardTop" ? (
          <img
            style={{ width: "100%", height: hoverCardHeight }}
            alt="image that slides over the background image from the top"
            src={imgUrls[1]}
          />
        ) : (
          ""
        )}
      </div>

      {/* hover text */}
      <div
        className={slideState ? `${hoverCaptionClass}  ` : `${hoverCaption}`}
      >
        <div style={{ verticalAlign: "middle", height: 20 }}>
          {/* {hoverCaptionText} */}
        </div>
      </div>

      {/* background imag */}
      <div className="imgWraper">
        {imgUrls ? (
          <a href={imgUrls[2]}>
            <img
              role="button"
              type="button"
              style={{
                zIndex: 1,
                //   minHeight: "600px",
                height: 640,
                //   height: "90vh",
                width: "100%",
                position: "relative",
              }}
              alt={`link to Belcastro company ${imgUrls[2].split("/")[2]}`}
              src={imgUrls[0]}
            />
          </a>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default Slide;
