import React from "react";
import { FacebookIcon, LinkedinIcon, TwitterIcon } from "react-share";
import "./style.css";
function Header() {
  function goHere(url) {
    window.open(url);
  }
  function handleKeyPress(event, path) {
    if (event.key === "Enter" || event.key === " ") {
      goHere(path);
    }
  }
  return (
    <div className="header">
      <div
        style={{
          border: "thin solid white",
          marginTop: "10px",
          height: "40px",
          marginBottom: "10px",
        }}
      >
        <FacebookIcon
          round
          size={40}
          className="icon"
          onClick={() => {
            goHere(
              "https://www.facebook.com/The-Belcastro-Group-109795444967092"
            );
          }}
          onKeyPress={(e) => {
            handleKeyPress(
              e,
              "https://www.facebook.com/The-Belcastro-Group-109795444967092"
            );
          }}
        />
        <LinkedinIcon
          round
          size={40}
          className="icon"
          onClick={() => {
            goHere("https://www.linkedin.com/in/peter-belcastro-770b5526/");
          }}
          onKeyPress={(e) => {
            handleKeyPress(
              e,
              "https://www.linkedin.com/in/peter-belcastro-770b5526/"
            );
          }}
        />

        <TwitterIcon
          round
          size={40}
          className="icon"
          onClick={() => {
            goHere("https://twitter.com/belcastrogroup1");
          }}
          onKeyPress={(e) => {
            handleKeyPress(e, "https://twitter.com/belcastrogroup1");
          }}
        />
      </div>
    </div>
  );
}

export default Header;
