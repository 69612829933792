import "./card.css";

const card = (props) => {
  return (
    <div className="card">
      <img src={props.img} alt={props.location} className="card_image" />
      <p className="card_text">{props.location}</p>
    </div>
  );
};

export default card;
