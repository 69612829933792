import React from "react";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";

const mapStyles = {
  width: "640px",
  height: "300px",
  // marginRight: 20,
};

const containerStyle = {
  position: 'absolute',  
  width: 'auto',
  height: 'auto'
}
function bcm({ google }) {
  return (
    <div>
      <Map
        containerStyle={containerStyle}
        google={google}
        zoom={14}
        style={mapStyles}
        initialCenter={{
          lat: 33.88953153722873,
          lng: -118.27744994861564,
          //33.90693836947494, -118.27744994861564
        }}
        >
        <Marker
        title={'Belcastro Construction Management'}
        name={'Belcastro Construction Management'}
        position={{lat: 33.88953153722873, lng: -118.27744994861564}} />
        </Map>
    </div>
  );
}

function ayard({ google }) {
  return (
    <div>
      <Map
        google={google}
        zoom={14}
        style={mapStyles}
        initialCenter={{
          lat: 33.88953153722873,
          lng: -118.27744994861564,
          //33.90693836947494, -118.27744994861564
        }}
      >
      <Marker
      title={'Ayard Rentals'}
      name={'Ayard Rentals'}
      position={{lat: 33.88953153722873, lng: -118.27744994861564}} />
      </Map>
    </div>
  );
}
function wholesale({ google }) {
  return (
    <div>
      <Map
        google={google}
        zoom={14}
        style={mapStyles}
        initialCenter={{
          lat: 33.88953153722873,
          lng: -118.27744994861564,
          //33.90693836947494, -118.27744994861564
        }}
      >
      <Marker
      title={'CB Wholesale Electric'}
      name={'CB Wholesale Electric'}
      position={{lat: 33.88953153722873, lng: -118.27744994861564}} />
      </Map>
    </div>
  );
}
function design({ google }) {
  return (
    <div>
      <Map
        google={google}
        zoom={14}
        style={mapStyles}
        initialCenter={{
          lat: 33.88953153722873,
          lng: -118.27744994861564,
          //33.90693836947494, -118.27744994861564
        }}
      >
      <Marker
      title={'CB Design'}
      name={'CB Design'}
      position={{lat: 33.88953153722873, lng: -118.27744994861564}} />
      </Map>
    </div>
  );
}
function logistics({ google }) {
  return (
    <div>
      <Map
        google={google}
        zoom={14}
        style={mapStyles}
        initialCenter={{
          lat: 33.88953153722873,
          lng: -118.27744994861564,
          //33.90693836947494, -118.27744994861564
        }}
      >
      <Marker
      title={'CB Logistics'}
      name={'CB Logistics'}
      position={{lat: 33.88953153722873, lng: -118.27744994861564}} />
      </Map>
    </div>
  );
}

const MapDesign = GoogleApiWrapper({
  apiKey: "AIzaSyDeDR4EYcDCjpdU66McbX2gjhD_ABjEZqg",
})(design);
const MapLogistics = GoogleApiWrapper({
  apiKey: "AIzaSyDeDR4EYcDCjpdU66McbX2gjhD_ABjEZqg",
})(logistics);

const MapBCM = GoogleApiWrapper({
  apiKey: "AIzaSyDeDR4EYcDCjpdU66McbX2gjhD_ABjEZqg",
})(bcm);

const MapWholesale = GoogleApiWrapper({
  apiKey: "AIzaSyDeDR4EYcDCjpdU66McbX2gjhD_ABjEZqg",
})(wholesale);
// export default MyMapComponent;
const MapAyard = GoogleApiWrapper({
  apiKey: "AIzaSyDeDR4EYcDCjpdU66McbX2gjhD_ABjEZqg",
})(ayard);

export { MapAyard, MapBCM, MapWholesale, MapDesign, MapLogistics };
