import "./about.css";

const History = () => {
  return (
    <>
      <header className="member_title">
        <div style={{ fontSize: "18px" }}>OUR HISTORY</div>
        <h1 className="h1Test">HOW WE GOT WHERE WE ARE</h1>
      </header>

      <div className="container" style={{ marginTop: "1%" }}>
        <div
          className="row"
          style={{
            borderBottom: "1px solid #2c4c6c",
            marginBottom: "2%",
            padding: "10px 0",
          }}
        >
          <div className="col-md-2 text-center timeline_year">2018</div>
          <div className="col-md-4 logo_container">
            <img
              className="logos"
              src="/images/logos/LOGO - BCM honey comb - square - 320x320.webp"
              alt="BCM Logo"
            />
            <img
              className="logos"
              src="/images/logos/LOGO - CB Wholesale - white background - square - 360x360.webp"
              alt="Wholesale Electric"
            />
          </div>
          <div className=" col-md-6 timeline_year-info">
            <h2>NOVEMBER</h2>
            <p>
              Belcastro Construction Management &amp; CB Procurement open for
              Business
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-2 timeline_year">2019</div>
          <div className="col-md-4 logo_container">
            <img
              className="logos"
              src="/images/logos/LOGO - BCM honey comb - square - 320x320.webp"
              alt="BCM"
            />
            <img
              className="logos"
              src="/images/logos/LOGO - United Airlines - 300x300.webp"
              alt="United Airlines"
            />
          </div>
          <div className="col-md-6 timeline_year-info">
            <h2>FEBRUARY</h2>
            <p>
              BCM is contracted as Program Construction Manager for the United
              Airlines Hanger at LAX
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-2 timeline_year"></div>
          <div className="col-md-4 logo_container">
            <img
              className="logos"
              src="/images/logos/LOGO - AYARD - 360x360.webp"
              alt="Ayard Logo"
            />
          </div>
          <div className="col-md-6 timeline_year-info">
            <h2>APRIL</h2>
            <p> </p>
            <p>Ayard Rentals opens it doors</p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-2 timeline_year"></div>
          <div className="col-md-4 logo_container">
            <img
              className="logos"
              src="/images/logos/LOGO - CB-design - square - 320x320.webp"
              alt="CB Design"
            />
            <img
              className="logos"
              src="/images/logos/LOGO - Arup - 300x300.webp"
              alt="Arup Logo"
            />
          </div>
          <div className="col-md-6 timeline_year-info">
            <h2></h2>
            <p> </p>
            <p>
              CB Design wins contract with City of Los Angeles with ARUP
              Partnership
            </p>
          </div>
        </div>

        <div className="row timeline_year_end">
          <div className="col-md-2 timeline_year"></div>
          <div className="col-md-4 logo_container">
            <img
              className="logos"
              src="/images/logos/LOGO - BCM honey comb - square - 320x320.webp"
              alt="BCM"
            />
            <img
              className="logos"
              src="/images/logos/LOGO - United Airlines - 300x300.webp"
              alt="United Airlines"
            />
          </div>
          <div className="col-md-6 timeline_year-info">
            <h2>SEPTEMEBER</h2>
            <p>
              {" "}
              BCM increases support for United Airlines Hangar Project at LAX
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-2 timeline_year">2020</div>
          <div className="col-md-4 logo_container">
            <img
              className="logos"
              src="/images/logos/LOGO - BCM honey comb - square - 320x320.webp"
              alt="BCM"
            />
            <img
              className="logos"
              src="/images/logos/LOGO - United Airlines - 300x300.webp"
              alt="United Airlines"
            />
          </div>
          <div className="col-md-6 timeline_year-info">
            <h2>JANUARY</h2>
            <p>
              United Airlines contracts BCM for the Program Construction Manager
              for all First Class lounge upgrades
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-2 timeline_year"></div>
          <div className="col-md-4 logo_container">
            <img
              className="logos"
              src="/images/logos/LOGO - BCM honey comb - square - 320x320.webp"
              alt="BCM"
            />
            <img
              className="logos"
              src="/images/logos/LOGO - Clark - 300x300.webp"
              alt="Clark Logo"
            />
          </div>
          <div className="col-md-6 timeline_year-info">
            <h2>APRIL</h2>
            <p>
              BCM contracted to CLARK as construction manager for LADWP
              Recieving Station - X at LAX
            </p>
          </div>
        </div>

        <div className="row timeline_year_end">
          <div className="col-md-2 timeline_year"></div>
          <div className="col-md-4 logo_container">
            <img
              className="logos"
              src="/images/logos/LOGO - CB Logistics - square - 360x360.webp"
              alt="CB Logistics"
            />
          </div>
          <div className="col-md-6 timeline_year-info">
            <h2>JUNE</h2>
            <p>CB Logistics opens its doors</p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-2 timeline_year">2021</div>
          <div className="col-md-4 logo_container">
            <img
              className="logos"
              src="/images/logos/LOGO - BCM honey comb - square - 320x320.webp"
              alt="BCM Logo"
            />
            <img
              className="logos"
              src="/images/logos/LOGO - Clark - 300x300.webp"
              alt="Clark logo"
            />
          </div>
          <div className="col-md-6 timeline_year-info">
            <h2>JUNE</h2>
            <p>
              Clark and BCM close out the contract for onsite Construction
              management work on the LAX/DWP Receiving Station - X
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-2 timeline_year"></div>
          <div className="col-md-4 logo_container">
            <img
              className="logos"
              src="/images/logos/LOGO - AYARD - 360x360.webp"
              alt="Ayard Rentals"
            />
          </div>
          <div className="col-md-6 timeline_year-info">
            <h3>SEPTEMBER</h3>
            <p>
              AYARD RENTALS reaches $1 million in available rental equipment
            </p>
          </div>
        </div>

        <div className="row timeline_year_end">
          <div className="col-md-2 timeline_year"></div>
          <div className="col-md-4 logo_container">
            <img
              className="logos"
              src="/images/logos/LOGO - BCM honey comb - square - 320x320.webp"
              alt="BCM Logo"
            />
            <img
              className="logos"
              src="/images/logos/LOGO - PGAL - 300x300.webp"
              alt="PGal logo"
            />
          </div>
          <div className="col-md-6 timeline_year-info">
            <h3>OCTOBER</h3>
            <p>
              BCM is contracted to PGAL for onsite Construction Management work
              related to Punchlist & Closeout for the ConRAC
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-2 timeline_year">2022</div>
          <div className="col-md-4 logo_container">
            <img
              className="logos"
              src="/images/logos/LOGO - BCM honey comb - square - 320x320.webp"
              alt="BCM Logo"
            />
            <img
              className="logos"
              src="/images/logos/LOGO - United Airlines - 300x300.webp"
              alt="United Airlines"
            />
          </div>
          <div className="col-md-6 timeline_year-info">
            <h2>JANUARY</h2>
            <p>
              United Airlines signs BCM to a contract for Project Manager
              Services for their line station projects
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-2 timeline_year"></div>
          <div className="col-md-4 logo_container">
            <img
              className="logos"
              src="/images/logos/LOGO - BCM honey comb - square - 320x320.webp"
              alt="BCM Logo"
            />
            <img
              className="logos"
              src="/images/logos/LOGO - AGORA - 300x300.webp"
              alt="AGORA Realty"
            />
          </div>
          <div className="col-md-6 timeline_year-info">
            <h2>JULY</h2>
            <p>
              BCM Begins work as Construction Manager for Development Projects
              with AGORA Realty & Management, Inc.
            </p>
          </div>
        </div>

        {/* <div className="row">
        <div className="col-md-2 timeline_year"></div>
        <div className="col-md-4 logo_container">
          <img className="logos" src="/images/logos/LOGO - BCM honey comb - square - 320x320.webp" alt="BCM Logo" />
          <img className="logos" src="/images/logos/LOGO - Cal State LA logo 2 - 340x340.webp" alt="United Airlines" />
        </div>
        <div className="col-md-6 timeline_year-info">
          <h3>OCTOBER</h3>
            <p>BCM Signs California State University, Los Angeles for exterior cleaning contract for several buildings on campus</p>
        </div>
      </div> */}
      </div>
    </>
  );
};

export default History;
