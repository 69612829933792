import React, { useEffect } from "react";
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";

import Navbar from "../About/Nav/aboutNavbar";
import Footer from "../../components/Footer";
import "./style.css";
const Veteran = () => {
  const history = useHistory();

  const navHandler = (selectedKey) => {
    if (!selectedKey) {
      history.push("/");
    } else {
      history.push(`${selectedKey}`);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="pageContainer">
      <div className="about_container">
        <Navbar />
        <div className="whoweare_hero">
          <img
            src="/images/veteran/VETERANS - Banner - 1280x240.webp"
            alt="Veteran Outreach image"
          />
        </div>
        <div className="veteran_text">
          <p>
            Every warrior has a next mission. We know that the transition to
            civilian life is a journey. And for every warrior, family member,
            and caregiver, that journey looks different. Because we believe that
            every warrior should have a positive future to look forward to.
            There’s always another goal to achieve, another mission to discover.
            We are a partner in that mission. With many exciting positions
            available across the varied facets of our companies, we invite you
            to explore a great opportunity with a purpose-driven company.
          </p>
        </div>

        <div className="veteran_card-container">
          <div className="veteran_card">
            <div className="veteran_card-img">
              <img
                src="/images/veteran/VETERANS - Peter Belcastro - 250x300.webp"
                alt=""
              />
            </div>
            <div className="veteran_card-text">
              <p>PETER BELCASTRO</p>
              <p>HD - E6</p>
            </div>
          </div>
          <div className="veteran_card">
            <div className="veteran_card-img">
              <img
                src="/images/veteran/VETERANS - Danelle Skinner - navy - 250x300.webp"
                alt=""
              />
            </div>
            <div className="veteran_card-text">
              <p>DANELLE SKINNER</p>
              <p>HD - E4</p>
              {/*
                <p>Tours in Africa, Afganistan, Aisa</p>
                <p>4 Years Served, Explosive Ordinacemen</p>
                <p>2 Years Served, Seabees</p>
              */}
            </div>
          </div>
          <div className="veteran_card">
            <div className="veteran_card-img">
              <img
                src="/images/team/HDSHT - Ericka Tutino - 250x300.jpeg"
                alt=""
              />
            </div>
            <div className="veteran_card-text">
              <p>ERICKA TUTINO </p>
              <p>HD - E5</p>
            </div>
          </div>
          <div className="veteran_card">
            <div className="veteran_card-img">
              <img
                src="/images/team/VETERANS - Sondra Curran - 250x300.jpeg"
                alt=""
              />
            </div>
            <div className="veteran_card-text">
              <p>SONDRA CURRAN </p>
              <p>HD - E4</p>
            </div>
          </div>
        </div>
        <div>
          <Footer navHandler={navHandler} />
        </div>
      </div>
    </div>
  );
};
export default withRouter(Veteran);
