import { Fragment, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { data } from "./aboutData";

const Team = () => {
  const teamImages = data.team;

  const history = useHistory();

  const routeChange = (path) => {
    history.push(`${path}`);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      <header className="team_title d-flex align-items-center" role="banner">
        <h1 className="h1Test">OUR TEAM</h1>
      </header>
      <div className="team_img-box d-flex align-items-center justify-content-center">
        {teamImages.map((member, i) => {
          return (
            <div
              className="team_img-card"
              role="button"
              key={i}
              onClick={() => routeChange(member.linkPath)}
            >
              <img src={member.image} alt={member.name} />
            </div>
          );
        })}
      </div>
    </Fragment>
  );
};

export default Team;
