/* eslint-disable react/prop-types */
import React, { PureComponent } from "react";
import { withRouter } from "react-router";
import Footer from "../../components/Footer";

import {
  MapAyard,
  MapBCM,
  MapDesign,
  MapLogistics,
  MapWholesale,
} from "../../components/Map";
import "./contact.css";
import ReactGA from "react-ga";
class Contact extends PureComponent {
  constructor(props) {
    super(props);
    this.navHandler = this.navHandler.bind(this);
    this.state = {
      activeLogo: "bcm",
    };
  }
  navHandler(selectedKey) {
    if (!selectedKey) {
      this.props.history.push("/");
    } else {
      this.props.history.push(`${selectedKey}`);
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname);
  }

  render() {
    const active = this.state.activeLogo;
    let addy_1a, addy_1b, addy_2a, addy_2b;
    const companyHandler = (id) => {
      this.setState({ activeLogo: id });
    };
    let logo_box = "logo_box";
    let companyTitle = "Belcastro Construction Management";
    let phone_num = "424-427-6765";
    // let addy_1 = "P.O. Box 279";
    // let addy_2 = "Gardena, CA 90248";
    let site_hours = "8:00 AM - 5:00 PM";

    if (active === "bcm") {
      companyTitle = "Belcastro Construction Management";
      phone_num = "424-427-6765";
      addy_1a = "P.O. Box 279";
      addy_1b = "Gardena, CA 90248";
      addy_2a = "137 W. 157th Street";
      addy_2b = "Gardena, CA 90248";
      site_hours = "8:00 AM - 5:00 PM";
    }
    if (active === "ayard") {
      companyTitle = "Ayard Rentals";
      phone_num = "424-529-1351";
      addy_1a = "P.O. Box 279";
      addy_1b = "Gardena, CA 90248";
      addy_2a = "137 W. 157th Street";
      addy_2b = "Gardena, CA 90248";
      site_hours = "8:00 AM - 5:00 PM";
    }
    if (active === "design") {
      companyTitle = "CB Design";
      phone_num = "424-427-5424";
      addy_1a = "P.O. Box 279";
      addy_1b = "Gardena, CA 90248";
      addy_2a = "137 W. 157th Street";
      addy_2b = "Gardena, CA 90248";
      site_hours = "8:00 AM - 5:00 PM";
    }
    if (active === "wholesale") {
      companyTitle = "CB Wholesale Electric";
      phone_num = "424-427-5435";
      addy_1a = "P.O. Box 279";
      addy_1b = "Gardena, CA 90248";
      addy_2a = "137 W. 157th Street";
      addy_2b = "Gardena, CA 90248";
      site_hours = "8:00 AM - 5:00 PM";
    }
    if (active === "logistics") {
      companyTitle = "CB Logistics";
      phone_num = "424-600-6960";
      addy_1a = "P.O. Box 279";
      addy_1b = "Gardena, CA 90248";
      addy_2a = "137 W. 157th Street";
      addy_2b = "Gardena, CA 90248";
      site_hours = "8:00 AM - 5:00 PM";
    }

    return (
      <div className="pageContainer">
        <main className="contact_container" aria-label="main">
          <div className="contact_header">
            <p className="contact_header-title">Contact</p>
            <h1 className="contact_header-title">
              The Belcastro Group Companies
            </h1>
          </div>
          <div className="contact_companies-container">
            {/* <h1>Select a Company :</h1> */}
            <navigation className="contact_companies-logos ">
              <div
                className={
                  logo_box + (this.state.activeLogo === "bcm" ? " active" : "")
                }
                onClick={() => companyHandler("bcm")}
              >
                <img
                  src="/images/logos/LOGO - BCM honey comb - square - 320x320.webp"
                  alt="Belcastro Construction Management"
                />
              </div>
              <div
                className={
                  logo_box +
                  (this.state.activeLogo === "ayard" ? " active" : "")
                }
                onClick={() => companyHandler("ayard")}
              >
                <img
                  src="/images/logos/LOGO - AYARD - 360x360.webp"
                  alt="Ayard rentals"
                />
              </div>
              <div
                className={
                  logo_box +
                  (this.state.activeLogo === "design" ? " active" : "")
                }
                onClick={() => companyHandler("design")}
              >
                <img
                  src="/images/logos/LOGO - CB-design - square - 320x320.webp"
                  alt="CB Design"
                />
              </div>
              <div
                className={
                  logo_box +
                  (this.state.activeLogo === "wholesale" ? " active" : "")
                }
                onClick={() => companyHandler("wholesale")}
              >
                <img
                  src="/images/logos/LOGO - CB Wholesale - white background - square - 360x360.webp"
                  alt="CB Wholesale Electric"
                />
              </div>
              <div
                className={
                  logo_box +
                  (this.state.activeLogo === "logistics" ? " active" : "")
                }
                onClick={() => companyHandler("logistics")}
              >
                <img
                  src="/images/logos/LOGO - CB Logistics - square - 360x360.webp"
                  alt="CB Logistics"
                />
              </div>
            </navigation>
            <div style={{ width: "100%", textAlign: "center" }}>
              Select Company Above
            </div>
          </div>
          <div className="selected_company-title">
            <p>{companyTitle}</p>
          </div>
          <div className="company_info-container">
            <div className="company_info">
              <div className="company_info-data">
                <h1>Mailing Address :</h1>
                <p>{companyTitle}</p>
                <p>{addy_1a}</p>
                <p>{addy_1b}</p>
              </div>
              <div className="company_info-data">
                <h1>Warehouse Dropoff / Pickup :</h1>
                <p>{companyTitle}</p>
                <p>{addy_2a}</p>
                <p>{addy_2b}</p>
                <p>
                  TEL: <a href="tel:+"> {phone_num}</a>
                </p>
                <p>ON-SITE HOURS: {site_hours}</p>
              </div>
            </div>
            <div style={active === "bcm" ? {} : { display: "none" }}>
              <MapBCM />
            </div>
            <div style={active === "ayard" ? {} : { display: "none" }}>
              <MapAyard />
            </div>
            <div style={active === "design" ? {} : { display: "none" }}>
              <MapDesign />
            </div>
            <div style={active === "wholesale" ? {} : { display: "none" }}>
              <MapWholesale />
            </div>
            <div style={active === "logistics" ? {} : { display: "none" }}>
              <MapLogistics />
            </div>
          </div>
          <div className="company_info-container">
            <div className="company_info">
              <div className="company_info-data">
                <a href="/privacy">Privacy Policy</a>
              </div>
            </div>
          </div>
          <div>
            <Footer navHandler={this.navHandler} />
          </div>
        </main>
      </div>
    );
  }
}
export default withRouter(Contact);
