import { Link } from "react-router-dom";
import "./style.css";
//
const aboutNavbar = () => {
  return (
    <nav className="about_navbar" aria-label="main menu">
      <div className="about_link" role="link">
        <Link to="/about/founder">Our Founder</Link>
      </div>
      <div className="about_link" role="link">
        <Link to="/about/history">Our History</Link>
      </div>
      <div className="about_link" role="link">
        <Link to="/about/team">Our Team</Link>
      </div>
    </nav>
  );
};

export default aboutNavbar;
