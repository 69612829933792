const MobileContact = () => {
  return (
    <div style={{ backgroundColor: "white" }} className="pb-3">
      <div className="d-flex justify-content-center">
        TEL: <a href="tel:+"> 424-427-6765</a>
      </div>
      <div className="d-flex justify-content-center text-center">
        137 W. 157th Street Gardena, CA 90248 <br />
      </div>
    </div>
  );
};

export default MobileContact;
