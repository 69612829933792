import React, { useRef } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import MobileRow from "../../components/MobileRow/MobileRow";
import MobileTitle from "../../components/MobileTitle/MobileTitle";

import MobileContact from "./components/MobileContact";
function MobileIndex(props) {
  const myRef = useRef(null);

  const scrollTomainContent = () => {
    myRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <React.Fragment>
      <header>
        <a
          onClick={() => scrollTomainContent()}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 1000,
            color: "grey",
          }}
          href="#main-content"
        >
          Skip to main content
        </a>
        <Header />

        <MobileTitle title="The Belcastro Group" />
      </header>
      <main style={{ width: "100%" }} id="#main-content" ref={myRef}>
        <MobileRow
          name="AYARD Rentals"
          logo="/images/logos/LOGO - AYARD - 360x360.webp"
          image="/images/home_scoller/HOMEPAGE - AYARD - gereen tie-in - 715x1430 (1x2).webp"
          link="/companies/ayard-rentals"
          navHandler={props.navHandler}
        />
        <MobileRow
          name="Belcastro Construction Management"
          logo="/images/logos/LOGO - BCM honey comb - square - 320x320.webp"
          image="/images/home_scoller/HOMEPAGE - BCM - operated equipment - 680x1360 (1x2).webp"
          link="/companies/belcastro-construction-management"
          navHandler={props.navHandler}
        />
        <MobileRow
          name="CB Design"
          logo="/images/logos/LOGO - CB-design - square - 320x320.webp"
          image="/images/home_scoller/HOMEPAGE - cb-design - balsa - 680x1360 (1x2).webp"
          link="/companies/cb-designs"
          navHandler={props.navHandler}
        />
        <MobileRow
          name="CB Logistics"
          logo="/images/logos/LOGO - CB Logistics - square - 360x360.webp"
          image="/images/home_scoller/HOMEPAGE - CB Logistics - Warehouse - Munchen - 720x1440 (1x2).webp"
          link="/companies/cb-logistics"
          navHandler={props.navHandler}
        />
        <MobileRow
          name="CB Wholesale"
          logo="/images/logos/LOGO - CB Wholesale - white background - square - 360x360.webp"
          image="/images/home_scoller/HOMEPAGE - CB Wholesale Electric - Lighting - 820x1640 (1to2).webp"
          link="/companies/cb-wholesale"
          navHandler={props.navHandler}
        />
      </main>
      <div style={{ marginTop: "5%", width: "100%" }}></div>
      <footer>
        <Footer navHandler={props.navHandler} mobileScreen={true} />
        <MobileContact />
      </footer>
    </React.Fragment>
  );
}

export default MobileIndex;
