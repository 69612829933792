/* eslint-disable prettier/prettier */
const data = {
  "ayard-rentals": {
    loadingTime: 2,
    logo: "https://i.ibb.co/d7mWWjD/Ayard-Logo-OD-Green-on-White.jpg",
    loadingLogo: "https://i.ibb.co/Xt0vRHS/AYARD-spinner-1.gif",
    parallaxImages: [
      "/images/ayard/AYARD - entry - gereen tie - 1333x1000 (4x3).webp",
      "/images/ayard/AYARD - Generic Yard - 1333x1000 (4x3).webp",
      "/images/ayard/AYARD - End Page - 1333x1000 (4x3).webp",
    ],
    sectionOne: {
      background: "#49511E",
      color: "white",
      headingColor: "white",
      header: "Your full-service equipment provider in the LA area",
      paragraphLeft:
        "Now providing a large selection for your equipment rental needs. Get the equipment for your work site to get the job done right. We are dedicated to providing optimal customer service and well-maintained equipment for upmost efficiency at your work site.",
      paragraphRight:
        "We are dedicated to providing optimal customer service and well-maintained equipment for upmost efficiency at your work site.",
    },
    sectionTwo: {
      background: "white",
      color: "#49511E",
      headingColor: "#49511E",
      gridHeadingColor: "#49511E",
      header: "AYARD Rentals",
      paragraphs: [
        "AYARD Rentals is a Disabled Veteran Business Enterprise (DVBE) and Small Business Enterprise (SBE). Operating as a DVBE/SBE allows us the advantage of providing high-quality services that meet federal standards, goals, and requirements. Our organization offers quality equipment operating at peak performance. Ayard Rentals attributes our work ethics and success to our founder, Peter Belcastro, who always stresses quality and good business practices.",
        "We are very enthusiastic and passionate about providing the best service to our customers. Our mission is to provide high quality, competitively priced equipment with rental options that are versatile to your worksite needs.",
        "We would like you to consider Ayard Rentals for your next project! Please contact us to discuss how we can assist you.",
      ],
      grid: [
        {
          title: "Rentals",
          image:
            "/images/ayard/AYARD - banner-equipment-rentals - 380x211.webp",
          paragraph:
            "If you need daily, weekly, or monthly equipment to rent, then AYARD is here to help. With a variety of rentals to choose from, AYARD will exceed your expectations.",
        },
        {
          title: "Sales",
          image: "/images/ayard/AYARD - banner-equipment-sales - 380x211.webp",
          paragraph:
            "We sell a variety of equipment from leading manufacturers such as Multiquip, APT tools, JLG, Atlas, and Generac. Contact us and we will help you find the best equipment for your needs.",
        },
        {
          title: "Service",
          image: "/images/ayard/AYARD - banner-service - 380x211.webp",
          paragraph:
            "To ensure peak performance of rental equipment, we understand that preventative maintenance and routine service is crucial. AYARD’s equipment is maintained at optimum levels to minimize any downtime while on the job.",
        },
        {
          title: "Used",
          image: "/images/ayard/AYARD - banner-used-sales - 380x211.webp",
          paragraph: "Check out our used equipment available for sale!",
        },
      ],
    },
    //     background: "grey",   <<<---- ZACK I WAS TRYING TO GET THE ENTIRE BACKGROUND OF THE HORIZONTAL SCROLLER TO BE GREY, BUT IT ISN"T A MUST HAVE
    horizontalScrollerText: ">>>  Scroll Through To See Our Equipment  >>>",
    horizontalScroller: [
      {
        image:
          "/images/ayard/horiz-scroller/AYARD - AIR  COMPRESSORS - 500x500.webp",
        alt: "AYARD Compressors",
        //         text: "We provide Air Compressors - 175 - 195 CFM.",
      },
      {
        image: "/images/ayard/horiz-scroller/AYARD - air tools - 500x500.webp",
        alt: "AYARD Air Tools",
        //         text: "We provide Air Tools: Pavement Breakers (60# and 90#), Chipping Gun (25#), Rivet Buster.",
      },
      {
        image:
          "/images/ayard/horiz-scroller/AYARD - compaction equipment - 500x500.webp",
        alt: "AYARD Compaction Tools",
        //         text: "We provide Compaction tools; Rammers (2900lbs - 3600lbs) and VIB Plates (3000 lbs - 5000lbs).",
      },
      {
        image:
          "/images/ayard/horiz-scroller/AYARD - 10K Reach Fork - 500x500.webp",
        alt: "10K  reach forks",
        //         text: "We have 10K reach forks supplied by JLB.",
      },
      {
        image:
          "/images/ayard/horiz-scroller/AYARD - Light Towers - 500x500.webp",
        alt: "Light towers",
        //         text: "We can provide MLTS Light towers from GENERAC.",
      },
      {
        image:
          "/images/ayard/horiz-scroller/AYARD - Scissor Lifts - 500x500.webp",
        alt: "Sky Jack 19ft Scissor lift",
        //         text: "We can provide your site with brand new 19FT SKY Jack scissor lifts.",
      },
      {
        image:
          "/images/ayard/horiz-scroller/AYARD - 22ft Stakebed Truck - 500x500.webp",
        alt: "22ft Stakebed Truck",
        //         text: "We can provide your site with a 22FT Stakebed Truck for Delivery of materials.",
      },
      //       {
      //         image: "https://i.ibb.co/PM0t0KR/AYARD-5k-Reach-Fork-640x640-square.jpg",
      //         alt: "5K reach forks",
      //         text: "We can provide your site with brand new 5K reach forks, supplied by Genie.",
      //       },  <<<------ ZACK - I WANT TO GET RID OF THE 5K FORKLIFT PICTURE AND SHRINK THE SCOLLER (THERE IS A LITTLE GREY SLIVER AT THE END
      {
        image:
          "/images/ayard/horiz-scroller/AYARD - 500 Gal Waterbuffalo - 500x500.webp",
        alt: "Water Trailers",
        //         text: "We have a 500 Gallon Watter Buffalo to provide easy water access to anywhere on the Jobsite.",
      },
      {
        image:
          "/images/ayard/horiz-scroller/AYARD - 4k Water Truck 2 - 500x500.webp",
        alt: "Water Trucks",
        //         text: "We have a 4K and several 2K Water trucks to support your dust control needs.",
      },
      {
        image: "/images/ayard/horiz-scroller/AYARD - Welders - 500X500.webp",
        alt: "400 AMP welder",
        //         text: "Some text To go here",
      },
    ],
    //     teamMembers: [
    //       {
    //         name: "Pete Belcastro",
    //         image: "https://i.ibb.co/XkzKgcy/Peter-Belcastro.png",
    //         about:
    //           "Peter Belcastro is the founder and CEO of CB Procurement and has over 13 years construction industry experience in planning, project and construction management. Belcastro held positions as superintendent and project manager on projects valued over $1 billion. This wide variety of construction industry expertise from both a contractors and owners perspective enable Pete to provide exceptional services and deliver first rate solutions to his clients. After graduating UCLA in 2000, Belcastro went on to serve in the united states Naval Construction Battalion. He completes three oversees tours managing construction projects in Afghanistan, Africa, and Europe.",
    //       },
    //       {
    //        name: "Tony Mason",
    //         image: "https://i.ibb.co/PrbBFdf/Tony-Mason-Headshot.png",
    //         about:
    //           "Tony has been working with construction equipment for over 18 years, and concentrating on manageing rental fleets for the past 10 years. With an in-depth knowledge of equipment capabilities and a firm understanding of what equipment will best serve a clients needs he is always there for the clients to get the job done, and get it done well. More Coming soon...",
    //       },
    //     ],
    bottomAnimation: {
      background: "49511E", //s<<<------ ZACK - I WAS TRYING TO GET A VARIABLE THAT IS EDITABLE FOR THE BOTTOM ANIMATION COLOR - I AM NOT SURE THE DIFERENCE BETWEEN THESE TWO
      //       color: "#49511E",     <<<------ ZACK - I WAS TRYING TO GET A VARIABLE THAT IS EDITABLE FOR THE BOTTOM ANIMATION COLOR - I AM NOT SURE THE DIFERENCE BETWEEN THESE TWO
      headingOne: "Discover More about",
      headingTwo: "AYARD RENTALS",
      buttons: [
        {
          textOne: "VISIT",
          textTwo: "AYARD Rentals",
          url: "https://www.ayardrentals.com/",
        },
        {
          textOne: "WORK at",
          textTwo: "AYARD Rentals",
          url: "http://www.belcastro.group/career",
        },
      ],
    },
  },

  "belcastro-construction-management": {
    logo: "https://i.ibb.co/gvVTRww/BCM-honey-comb-logo-square.png",
    loadingLogo: "https://i.ibb.co/d20hX2J/BCM-spinner.gif",
    parallaxImages: [
      "/images/belcastro/BCM - operated equipment 2 - 1333x1000 (4x3).webp",
      "/images/belcastro/BCM - opener - 1333x1000 (4x3).webp",
      "/images/belcastro/BCM - On-site construction management - 1333x1000 (4x3).webp",
    ],
    sectionOne: {
      background: "#31486E",
      color: "white",
      headingColor: "white",
      header: "Your concierge Construction Management Service",
      paragraphLeft:
        "Our organization offers quality management and timely performance on all types of projects. We are registered and certified with:   Dunn and Brad Street: #081291548, California State DVBE/SB Certification: #2011548, Califonia State Licence Board: #104400 (Class B - DBA Belcastro Commercial Builders)",
      paragraphRight:
        "Our mission is to provide the Owners and General Contractors with a competitive, high quality, and timely completed projects while maintaining a productive and safe work environment for our employees and others on the project.",
    },
    sectionTwo: {
      background: "white",
      color: "#31486E",
      headingColor: "#31486E",
      gridHeadingColor: "#31486E",
      header: "Construction Management Services",
      paragraphs: [
        "Belcastro Construction Management is a Disabled Veteran Business Enterprise (DVBE) and Small Business Enterprise (SBE). Operating as a DVBE/SBE allows us the advantage of providing high-quality services that meet federal standards, goals, and requirements. Our organization offers quality equipment operating at peak performance. Belcastro Construction Management attributes our work ethics and success to our founder, Peter Belcastro, who always stresses quality and good business practices.",
        "We are very enthusiastic and passionate about providing the best service to our customers. Our mission is to provide high quality, competitively priced equipment with rental options that are versatile to your worksite needs.",
        "We would like you to consider Belcastro Construction Management for your next project! Please contact us to discuss how we can assist you.",
      ],
      grid: [
        {
          title: "Pre-Construction",
          image: "/images/belcastro/BCM - pre_construction - 280x187.webp",
          paragraph:
            "During pre-construction, the BCM team will work closely with you to develop and deliver projects that fully address your goals, efficiently and economically. We will stretch your dollars further by streamlining the process and reducing potential problems, delays, and claims through our proven review process:",
          list: [
            "Master scheduling",
            "Design consultant oversight",
            "Cost estimate review",
            "Value engineering",
            "Biddability/constructability (and maintainability) review",
            "Construction delivery strategies/methods",
            "Permit acquisition",
            "Contractor pre-qualifications",
            "Procurement and bidding assistance",
          ],
        },
        {
          title: "Construction",
          image: "/images/belcastro/BCM - construction_onsite - 280x180.webp",
          paragraph:
            "In the field, we are problem solvers, administrators, managers, and quality control observers. Effective management requires the ability to understand and critique the contractor’s schedule, anticipate and solve problems, inspect and identify non-compliant work, scope and negotiate changes, and most of all lead through good listening and clear communications. Among our many responsibilities during construction:",
          list: [
            "Documenting of pre-existing conditions",
            "Schedule management",
            "Cost estimate review",
            "Change order management",
            "Document control via state-of-the-art project management software",
            "Quality control and assurance",
            "Construction inspection",
            "Labor compliance monitoring",
            "Dispute resolution/claims avoidance",
          ],
        },
        {
          title: "Post-Construction",
          image: "/images/belcastro/BCM - post_construction - 280x210.webp",
          paragraph:
            "The close-out phase of any project begins on day one. BCM establishes the required close-out documents up front and continually monitors the gathering and collection process. We verify that all punch list corrections are completed and all systems tested. We provide training on new equipment, receive all guarantees and warranties, and wrap up all contractual money issues. BCM concludes project record keeping and provides a clear paper trail of the process for future review. We will set the stage for a smooth and efficient re-occupancy as we:",
          list: [
            "Close out the project",
            "Coordinate manufacturers’ training sessions",
            "Review as-built drawings for accuracy",
            "Resolve any warranty issues",
          ],
        },
      ],
    },
    sectionThree: {
      background: "grey",
      color: "#DDDDDF",
      headingColor: "#31486E",
      gridHeadingColor: "#31486E",
      header: "PROJECTS",
      grid: [
        {
          location: "The Grand",
          image: "/images/projects/PROJECTS - The_Grand 640x364.webp",
        },
        {
          location: "LA Clippers Stadium",
          image:
            "/images/projects/PROJECTS - LA Clippers Stadium - 640x360.webp",
        },
        {
          location: "LAX United Hanger",
          image:
            "/images/projects/PROJECTS - LAX-United-Hangar_1 - 640x394.webp",
        },
        {
          location: "Malibu High School",
          image:
            "/images/projects/PROJECTS - Malibu High School - Night - 640x426.webp",
        },
        {
          location: "Airport Police Facility LAX",
          image:
            "/images/projects/PROJECTS - Airport-Police-Facility-at-LAX_1 - 640x355.webp",
        },
        {
          location: "LINXS at LAX",
          image: "/images/projects/PROJECTS - APM - Night - 640x360.webp",
        },
        {
          location: "Los Angeles International Airport",
          image:
            "/images/projects/PROJECTS - LAX long distance with palms - 640x358.webp",
        },
        {
          location: "Aztec Stadium Renovation",
          image:
            "/images/projects/PROJECTS - Aztec Stadium Renovation - 640x400.webp",
        },
        {
          location: "San Diego Airport Terminal Renovation",
          image:
            "/images/projects/PROJECTS - SanDiegoAirport_Banner - 640x385.webp",
        },
      ],
    },
    teamMembers: [
      {
        name: "Pete Belcastro",
        image: "/images/team/HDSHT - Peter Belcastro - 300x300.webp",
        about:
          "The founder and CEO of CB Procurement and has over 13 years construction industry experience in planning, project and construction management. Belcastro held positions as superintendent and project manager on projects valued over $1 billion. This wide variety of construction industry expertise from both a contractors and owners perspective enable Pete to provide exceptional services and deliver first rate solutions to his clients. After graduating UCLA in 2000, Belcastro went on to serve in the united states Naval Construction Battalion. He completed three oversees tours managing construction projects in Afghanistan, Africa, and Europe.",
      },
      {
        name: "Ivan Raya",
        image: "/images/team/HDSHT - Ivan Raya - B-W - 300x300.webp",
        about:
          "Serves as a Sr. Construction Manager for Belcastro Construction Management, responding to all requirements and concerns of the Client. He has extensive experience in developing and monitoring project master schedules, estimating, job cost reports, and establishing and implementing effective communication procedures for all team components.  Recognized in the industry for his ability to isolate and resolve various design and compliance issues early in a project’s evolution, his aptitude and expertise are evidenced in the many successful projects with which he has been involved.  His project experience covers public and private clients under a wide variety of project delivery methods.  Mr. Raya is also highly skilled at supervising and facilitating the work activities of all trades on dynamic construction jobsites; coordinating work activities and logistics plans with construction crews and project stakeholders.",
      },

      {
        name: "Michael J. Brown",
        image: "/images/team/HDSHT - MJB - B-W - 300x300.webp",
        about:
          "An experienced Structural Engineer and Construction Manager with over 13 years of success analyzing, designing, and detailing Steel, Concrete, Masonry, and Light Gauge Metal Structures used for Residential, Office, Hospitality, Mixed-Use, and Education purposes. Worked directly with Project Manager and/or Partner, aiding with coordination eforts and mentoring Staf Engineers. Leader of Construction Administration efforts by attending OAC meetings, conducting shop drawing reviews, providing RFI solutions, conducting structural site observations, and preparing site visit reports.  Led Coordination between Owner, Design Team, and CMAR for construction logistics, Design review and interface with LAWA team.",
      },
    ],
    bottomAnimation: {
      background: "31486E",
      headingOne: "Discover More About",
      headingTwo: "BELCASTRO CONSTRUCTION MANAGEMENT",
      buttons: [
        {
          textOne: "Visit",
          textTwo: "BCM",
          url: "https://www.belcastroinc.com/",
        },
        {
          textOne: "Work at",
          textTwo: "BCM",
          url: "http://www.belcastro.group/career",
        },
      ],
    },
  },

  "cb-design": {
    name: "CB Design",
    logo: "https://i.ibb.co/23vT5Km/cb-design-logo-white-text-on-CB-Blue-Background-square.png",
    loadingLogo: "https://i.ibb.co/3M0Fk13/CB-Design-Loader.gif",
    parallaxImages: [
      "/images/cb_design/CB DESIGN - entry - 1333x1000 (4x3).webp",
      "/images/cb_design/CB Design - blueprint - 1333x1000 (4x3).webp",
      "/images/cb_design/cb-design-2-large-1333x1000-4x3.webp",
    ],
    sectionOne: {
      background: "#31486E",
      color: "white",
      headingColor: "white",

      header: "Your premier construction designer in the LA area",
      paragraphLeft:
        "A key component to successful projects is the efficient use of the latest technology for coordination and design between design professionals and the contractor.  Our passion is to create this seamless structural design to construction process through efficient use of the latest technologies.",
      paragraphRight:
        "We are dedicated to resiliency and sustainability in our projects.  Our mission is to ensure the projects performance over the long-term and reduce the client’s risk to both natural and human created disasters.  We view each project as a step towards a sustainable and resilient future.",
    },
    sectionTwo: {
      background: "white",
      color: "black",
      headingColor: "#31486E",
      gridHeadingColor: "#31486E",
      header: "CB Design",
      paragraphs: [
        "CB Design offers a fresh approach to building design management and structural engineering.  The next generation of design services requires collaboration at every step, and our design professionals ensure that we innovate and guide the project from conception to completion.",
        "CB Design will manage the design process and professionals, or can be an integral team member to accomplish the design efficiently.  Design management ensures full integration between the design professionals and working closely with the architect to realize the client’s vision.  The design management also includes an integrated relationship to ensure the design can efficiently be built on time and within budget.  A key component to a successful project is implementing the latest technology for coordination of the design information and modeling between design professionals and the contractor.  Our goal is to create a seamless building process.",
        "The structural and earthquake design focuses on resiliency of the structure.  For most buildings a non-linear performance-based analysis, loss estimate, and repair time estimate for an earthquake will be provided to the client and architect to make smart building system decisions.  Using the latest in earthquake engineering, the client can make choices on how to improve their New or Retrofit building’s performance during an earthquake and reduce their risk after the earthquake.  Our mission is to create a resilient built environment with each project.",
      ],
    },
    sectionThree: {
      background: "white",
      color: "#809440",
      headingColor: "#31486E",
      gridHeadingColor: "#809440",
      header: "Projects",
      grid: [
        {
          location: "The Grand",
          image: "/images/projects/PROJECTS - The_Grand 640x364.webp",
        },
        {
          location: "LA Clippers Stadium",
          image:
            "/images/projects/PROJECTS - LA Clippers Stadium - 640x360.webp",
        },
        {
          location: "LAX United Hanger",
          image:
            "/images/projects/PROJECTS - LAX-United-Hangar_1 - 640x394.webp",
        },
        {
          location: "Malibu High School",
          image:
            "/images/projects/PROJECTS - Malibu High School - Night - 640x426.webp",
        },
        {
          location: "Airport Police Facility LAX",
          image:
            "/images/projects/PROJECTS - Airport-Police-Facility-at-LAX_1 - 640x355.webp",
        },
        {
          location: "LINXS at LAX",
          image: "/images/projects/PROJECTS - APM - Night - 640x360.webp",
        },
        {
          location: "Los Angeles International Airport",
          image:
            "/images/projects/PROJECTS - LAX long distance with palms - 640x358.webp",
        },
        {
          location: "Aztec Stadium Renovation",
          image:
            "/images/projects/PROJECTS - Aztec Stadium Renovation - 640x400.webp",
        },
        {
          location: "San Diego Airport Terminal Renovation",
          image:
            "/images/projects/PROJECTS - SanDiegoAirport_Banner - 640x385.webp",
        },
      ],
    },
    teamMembers: [
      {
        name: "Pete Belcastro",
        image: "/images/team/HDSHT - Peter Belcastro - 300x300.webp",
        about:
          "Peter Belcastro is the founder and CEO of CB Procurement and has over 13 years construction industry experience in planning, project and construction management. Belcastro held positions as superintendent and project manager on projects valued over $1 billion. This wide variety of construction industry expertise from both a contractors and owners perspective enable Pete to provide exceptional services and deliver first rate solutions to his clients. After graduating UCLA in 2000, Belcastro went on to serve in the united states Naval Construction Battalion. He completes three oversees tours managing construction projects in Afghanistan, Africa, and Europe.",
      },
      {
        name: "Larwence Pitkin",
        image: "/images/team/HDSHT - Lawrence Pitkin - 300x300.webp",
        about:
          "A Los Angeles native Structural Engineer and Construction Manager with over 15 years of experience in the engineering and construction fields. Successfully manages a diverse array of structural design and construction projects, including aviation, hospitals, schools, and retrofits. Possesses a unique understanding for the project goals, requirements and work processes that facilitate successful realization of projects.",
      },
      {
        name: "Jamie Garza",
        image: "h/images/team/HDSHT - Jaime_Garza - 300x300.webp",
        about:
          "Jaime Garza has over 16 years of experience in structural and earthquake engineering design.  She has led the structural design on project in Education, Civic, Aviation, Residential, Hospitality, Fin Arts, Performing Arts, Health-care, and Commercial sectors.  Ms. Garza utilized performance-based engineering for the design of new buildings, the retrofit of existing buildings, and the evaluation of existing building of over 40 buildings.  She led the innovative retrofit of the LAX Theme Building using a mass damper on top of the building to counteract earthquake shaking.  She recently co-authored the paper “Should We Rely on Existing Building Lateral Systems when Retrofitting Building?” and presented the findings during the 2018 Structural Engineering Association of California Convention.  The research provides an overview of building codes and analysis tools, as well as a complete picture of what earthquake resiliency requires.  She is committed to collaborating with clients and architects to truly implement resilient structural design.",
      },
      {
        name: "Michael J. Brown",
        image: "/images/team/HDSHT - MJB - 300x300.webp",
        about:
          "An experienced Structural Engineer and Construction Manager with over 13 years of success analyzing, designing, and detailing Steel, Concrete, Masonry, and Light Gauge Metal Structures used for Residential, Office, Hospitality, Mixed-Use, and Education purposes. Worked directly with Project Manager and/or Partner, aiding with coordination eforts and mentoring Staf Engineers. Leader of Construction Administration efforts by attending OAC meetings, conducting shop drawing reviews, providing RFI solutions, conducting structural site observations, and preparing site visit reports.  Led Coordination between Owner, Design Team, and CMAR for construction logistics, Design review and interface with LAWA team.",
      },
    ],
    bottomAnimation: {
      background: "31486E", //<<<------ ZACK - I WAS TRYING TO GET A VARIABLE THAT IS EDITABLE FOR THE BOTTOM ANIMATION COLOR - I AM NOT SURE THE DIFERENCE BETWEEN THESE TWO
      //       color: "#31486E",     <<<------ ZACK - I WAS TRYING TO GET A VARIABLE THAT IS EDITABLE FOR THE BOTTOM ANIMATION COLOR - I AM NOT SURE THE DIFERENCE BETWEEN THESE TWO
      headingOne: "Discover More",
      headingTwo: "About CB DESIGN",
      buttons: [
        {
          textOne: "VISIT",
          textTwo: "CB Design",
          url: "https://www.belcastroandassociates.com/",
        },
        {
          textOne: "WORK at",
          textTwo: "CB Design",
          url: "http://www.belcastro.group/career",
        },
      ],
    },
  },

  "cb-logistics": {
    name: "CB Logistics",
    logo: "/images/logos/LOGO - CB Logistics - square - 360x360.webp",
    loadingLogo: "https://i.ibb.co/PZ74sQb/Loading-Spinner-Animated.gif",
    parallaxImages: [
      "/images/cb_logistics/CB Logistics - Warehouse - Munchen - 1333x1000 (4x3).webp",
      "/images/cb_logistics/CB LOGISTICS - Man in Warehouse Forklift - 1086x814 (4x3).webp",
      "/images/cb_logistics/CB Logistics - commercial-vehicle-trucking - 1311x983 (4x3).webp",
    ],
    sectionOne: {
      background: "#31486E",
      color: "white",
      headingColor: "white",
      header: "CONSTRUCTION LOGISTICS SERVICES TO BEST SERVE YOUR NEEDS",
      paragraphLeft:
        "CB Logistics is a Disabled Veteran Business Enterprise (DVBE) and Small Business Enterprise (SBE) that has been providing construction logistics and managerial services to Owners and General Contractors since 2020. Operating as a DVBE/SBE, allows us an advantage of providing high quality services that meet federal standards, goals, and requirements.",
      paragraphRight:
        "Our organization offers quality management and timely performance on all types of projects. We are very enthusiastic and passionate about providing the best service to our customers. At CB Logistics we utilize experienced Construction Professionals to modernize Procurement and Logistics for the Construction Industry",
    },
    sectionTwo: {
      background: "white",
      color: "#809440",
      headingColor: "#31486e",
      gridHeadingColor: "#809440",
      header: "Services Provided",
      paragraphs: [""],
      grid: [
        {
          title: "Storage and Management of Materials",
          image:
            "/images/cb_logistics/CB LOGISTICS - Gardena Warehouse - 300x200.webp",
          paragraph: "",

          list: [
            "Long Lead Items",
            "Minimal to No on-site lay down space",
            "Escalation cost control",
          ],
        },
        {
          title: "Project Visibility (Virtual Warehouse - 3PL)",
          image: "/images/cb_logistics/CB LOGISTICS - Warehouse - 300x200.webp",
          paragraph: "",
          list: [
            "Digital inventory visibility",
            "Easy online ordering allowing for concentrated orders",
            "Material Lead time & inventory tracking",
          ],
        },
        {
          title: "Material Transportation and Handling",
          image:
            "/images/cb_logistics/CB LOGISTICS - Trucking Options - 300x200.webp",
          paragraph: "",
          list: ["Shipping", "Just-in-Time delivery"],
        },
      ],
    },

    sectionFour: {
      value: true,
    },

    teamMembers: [
      {
        name: "Pete Belcastro",
        image: "/images/team/HDSHT - Peter Belcastro - 300x300.webp",
        about:
          "The founder and CEO of CB Procurement and has over 13 years construction industry experience in planning, project and construction management. Belcastro held positions as superintendent and project manager on projects valued over $1 billion. This wide variety of construction industry expertise from both a contractors and owners perspective enable Pete to provide exceptional services and deliver first rate solutions to his clients. After graduating UCLA in 2000, Belcastro went on to serve in the united states Naval Construction Battalion. He completes three oversees tours managing construction projects in Afghanistan, Africa, and Europe.",
      },

      {
        name: "Chris Acuesta",
        image: "/images/team/HDSHT - Chris Acuesta - 300x300.webp",
        about:
          "An experienced wharehouse manager with over 15 years of experience in the warehousing industry. Chris has experiance with handling and storing vast types of materials. Full knowledge of the needs of each project to help maintain the efficient storage .",
      },
    ],
    bottomAnimation: {
      background: "31486E",
      headingOne: "Discover More about",
      headingTwo: "CB LOGISTICS",
      buttons: [
        {
          textOne: "VISIT",
          textTwo: "CB Logistics",
          url: "https://www.belcastroinc.com/",
        },
        {
          textOne: "WORK at",
          textTwo: "CB Logistics",
          url: "https://www.belcastroinc.com/",
        },
      ],
    },
  },

  "cb-wholesale": {
    logo: "/images/logos/LOGO - CB Wholesale - white background - square - 360x360.webp",
    loadingLogo: "https://i.ibb.co/PZ74sQb/Loading-Spinner-Animated.gif",
    parallaxImages: [
      "/images/cb_wholesale/CB WHOLESALE - Entry - Parking Structure - 1333x1000 (4x3).webp",
      "/images/cb_wholesale/CB WHOLESALE - United hangar interior - 1280x960 (4x3).webp",
      "/images/cb_wholesale/CB WHOLESALE - warehouse lighting - 1280x960 (4x3).webp",
    ],
    sectionOne: {
      background: "white",
      color: "#31486E",
      headingColor: "#31486E",
      header: "WHOLESALE ELECTRIC SERVICES",
      scrollDownBackground: "#31486e",
      paragraphLeft:
        "CB Wholesale Electric operates as a Disabled Veteran Business Enterprise (DVBE) and Small Business Enterprise (SBE) providing electrical, lighting, engineering, rental equipment and other construction supplies to contractors. Operating as a DVBE and SBE, allows us an advantage of providing high quality services that meets state and federal standards, goals, and requirements.",
      paragraphRight:
        "With over 40+ years combined experience, We are very enthusiastic and passionate about our involvement in all our construction projects. CB Wholesale Electric takes pride in our results, driven attitude, and values our relationships with clients for future business.  We are very quality conscience and consider our projects some of the finest in the state.",
    },
    sectionTwo: {
      background: "white",
      color: "#31486E",
      headingColor: "#31486E",
      gridHeadingColor: "#31486E",
      header: "PROFESSIONAL SERVICES",
      paragraphs: [
        "We would like you to consider CB Wholesale Electric for your next project!",
      ],
      grid: [
        {
          title: "PROJECT MANAGEMENT",
          image:
            "/images/cb_wholesale/CB WHOLESALE - Exterior Lighting - 612x335.webp",

          paragraph:
            "Not all projects are created equal and our team comprehends that. From project conception, project completion and post project support, our team will be by your side to tackle all challenges and replace them with solutions at every step of the way.",
          //   list: [],
        },
        {
          title: "DESIGN SERVICES",
          image:
            "/images/cb_wholesale/CB WHOLESALE - circular lighting fictures ceiling - 612x408.webp",

          paragraph:
            "A great lighting design requires a lot of Know-How in order to create the perfect lighting experience. Whether its New Construction or Renovations, our team has the expertise and resources to assure your project meets code, budget, and enhances your visual experience with quality solutions.",
          //   list: [],
        },
        {
          title: "PRODUCT VETTING",
          image:
            "/images/cb_wholesale/CB WHOLESALE - Electrical Conduit - 612x408.webp",

          paragraph:
            "We understand “On-Time” delivery is of the essence; budget restraints can be a headache and reliability is critical. At CB Wholesale, we keep your needs at our TOP PRIORITY. Our team is committed in simplifying your vetting process by providing you with solutions to meet your budget and without sacrificing quality. With our reliable vendor relationships, we are confident exceed your expectations.",
          //   list: [],
        },
        {
          title: "PROJECT SPECIFICATIONS",
          image:
            "/images/cb_wholesale/CB WHOLESALE - Electrical Conduit & Cable tray - 612x408.webp",

          paragraph:
            "With boots on the ground, our team is ready to visit your facility, identify the problem and assist your design team achieve project goals. Our team will specify and develop a custom solution tailored specifically for your needs.",
          //   list: [],
        },
      ],
    },
    sectionThree: {
      background: "white",
      color: "#809440",
      headingColor: "#809440",
      gridHeadingColor: "#809440",
      header: "Projects",
      grid: [
        {
          location: "The Grand",
          image: "/images/projects/PROJECTS - The_Grand 640x364.webp",
        },
        // {
        //   location: "LA Clippers Stadium",
        //   image: "/images/projects/PROJECTS - LA Clippers Stadium - 640x360.webp",
        // },
        {
          location: "LAX United Hanger",
          image:
            "/images/projects/PROJECTS - LAX-United-Hangar_1 - 640x394.webp",
        },
        {
          location: "Malibu High School",
          image:
            "/images/projects/PROJECTS - Malibu High School - Night - 640x426.webp",
        },
        {
          location: "Airport Police Facility LAX",
          image:
            "/images/projects/PROJECTS - Airport-Police-Facility-at-LAX_1 - 640x355.webp",
        },
        // {
        //   location: "Los Angeles International Airport",
        //   image: "/images/projects/PROJECTS - LAX long distance with palms - 640x358.webp",
        // },
        // {
        //   location: "Aztec Stadium Renovation",
        //   image: "/images/projects/PROJECTS - Aztec Stadium Renovation - 640x400.webp",
        // },
        // {
        //   location: "San Diego Airport Terminal Renovation",
        //   image: "/images/projects/PROJECTS - SanDiegoAirport_Banner - 640x385.webp",
        // },
        {
          location: "LINXS at LAX",
          image: "/images/projects/PROJECTS - APM - Night - 640x360.webp",
        },
      ],
    },
    teamMembers: [
      {
        name: "Pete Belcastro",
        image: "/images/team/HDSHT - Peter Belcastro - 300x300.webp",
        about:
          "Peter Belcastro is the founder and CEO of CB Procurement and has over 13 years construction industry experience in planning, project and construction management. Belcastro held positions as superintendent and project manager on projects valued over $1 billion. This wide variety of construction industry expertise from both a contractors and owners perspective enable Pete to provide exceptional services and deliver first rate solutions to his clients. After graduating UCLA in 2000, Belcastro went on to serve in the united states Naval Construction Battalion. He completes three oversees tours managing construction projects in Afghanistan, Africa, and Europe.",
      },
      {
        name: "Jarrod Kadonaga",
        image: "/images/team/HDSHT - Jarrod_Kadonaga - B-W - 300x300.webp",
        about:
          "General Manager, CB Wholesale Electric. Jarrod Kadonaga serves as the General Manager of CB Wholesale Electric, where he oversees sales and purchasing for the company. Prior to joining CBWE, Mr. Kadonaga spent 25 years in the electrical contracting/distribution industry where he served in a variety of positions from inside sales rep to operations manager. Jarrod enjoys fishing with his nephews and competitively racing RC cars.",
      },
    ],
    bottomAnimation: {
      background: "31486E",
      headingOne: "Discover More about",
      headingTwo: "CB WHOLESALE ELECTRIC",
      buttons: [
        {
          textOne: "VISIT",
          textTwo: "CB Wholesale",
          url: "https://www.cbwholesaleelectric.com/",
        },
        {
          textOne: "WORK at",
          textTwo: "CB Wholesale",
          url: "http://www.belcastro.group/career",
        },
      ],
    },
  },
};

export default data;
