import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import HomePage from "../pages/HomePage";
import CompanyPage from "../pages/CompanyPage";
import Contact from "../pages/Contact/contact";
import Veteran from "../pages/Veteran";
import About from "../pages/About/about.jsx";
import Career from "../pages/Career";
import ComingSoon from "../components/ComingSoon";
import Privacy from "../pages/Privacy";
// import Store from '../contexts/user'

export default function Routes() {
  return (
    <Router>
      {/* <Store> */}
      {/* <Navigation /> */}
      <Switch>
        <Route
          key="home"
          exact
          path="/homepage"
          component={() => {
            return <HomePage />;
          }}
        />

        <Route
          key="dashboard"
          exact
          path="/companies/:company"
          component={() => {
            return <CompanyPage />;
          }}
        />

        <Route
          key="the-overlap"
          exact
          path="/the-overlap"
          component={() => {
            return <HomePage />;
          }}
        />
        <Route
          key="root"
          exact
          path="/"
          component={() => {
            return <HomePage />;
          }}
        />
        <Route
          key="contact"
          exact
          path="/contact"
          component={() => {
            return <Contact />;
          }}
        />

        <Route
          key="veteran_outreach"
          exact
          path="/veteran_outreach"
          component={() => {
            return <Veteran />;
          }}
        />
        <Route
          key="about"
          exact
          path="/about"
          component={() => {
            return <About />;
          }}
        />
        <Route
          key="about"
          exact
          path="/about/:id"
          component={() => {
            return <About />;
          }}
        />
        <Route
          key="about"
          exact
          path="/about/team/:id"
          component={() => {
            return <About />;
          }}
        />
        <Route
          key="career"
          exact
          path="/career"
          component={() => {
            return <Career />;
          }}
        />
        <Route
          key="privacy url"
          exact
          path="/privacy"
          component={() => {
            return <Privacy />;
          }}
        />
        <Route
          key="none"
          exact
          path="/*"
          component={() => {
            return <ComingSoon />;
          }}
        />
      </Switch>
      {/* </Store> */}
    </Router>
  );
}
