// @flow
import * as React from "react";
import Footer from "../Footer";
import { withRouter } from "react-router";

const ComingSoon = (props) => {
  function navHandler(selectedKey) {
    if (!props || !props.history) return;
    if (!selectedKey && props && props.history) {
      props.history.push("/");
    } else {
      props.history ? props.history.push(`${selectedKey}`) : "";
    }
  }
  return (
    <div className="pageContainer">
      <div
        style={{
          height: 600,
          width: "100%",
          textAlign: "center",
          background: "white",
          marginTop: 50,
        }}
        className="d-flex align-items-center justify-content-center"
      >
        <h1>Coming Soon...</h1>
      </div>
      <div>
        <Footer navHandler={navHandler} />
      </div>
    </div>
  );
};
export default withRouter(ComingSoon);
