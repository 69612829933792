import classes from "./MobileTitle.module.css";
import PropTypes from "prop-types";

const MobileTitle = (props) => {
  return (
    <div className="fade-in-text">
      <h1 className={classes["mobile-title"]}>{props.title}</h1>
    </div>
  );
};
MobileTitle.propTypes = {
  title: PropTypes.string,
};

export default MobileTitle;
