import { Fragment } from "react";

import { members } from "./aboutData";

const teamDetails = (props) => {
  const nameFromLink = props.member;
  const nameString = nameFromLink
    .split("_")
    .map((item) => `${item.charAt(0).toUpperCase()}${item.slice(1)}`)
    .join(" ");

  const member = members.find((item) => {
    return item.name.replace(/\./g, "") == nameString;
  });
  //const member = members[props.member];
  // console.log("member found ", member);
  let data;

  if (typeof member === "undefined") {
    data = <div>more coming soon</div>;
  }

  const { name, title, image, education, licenses, certifications, about } =
    member ? member : {};

  name
    ? (data = (
        <Fragment>
          <header className="member_title">
            <h1>{name}</h1>
            <h2>{title}</h2>
          </header>

          <div className="member_details row my-5" role="main">
            <div className="col-4 justify-content-center member_image">
              <img src={image} alt={name} />
            </div>

            <div
              className="col-8 justify-content-center pr-0"
              style={{ backgroundColor: "whitesmoke" }}
            >
              <div className="member_info">
                {education ? <h2>EDUCATION</h2> : <h2>BIOGRAPHY</h2>}
                {education ? (
                  education.map((item, k) => {
                    return (
                      <div key={k} style={{ marginBottom: 10 }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p>{item.school}</p>
                          <p>{item.date}</p>
                        </div>
                        <p>{item.degree}</p>
                      </div>
                    );
                  })
                ) : (
                  <p>{about}</p>
                )}
                {licenses ? <h2>LICENSES</h2> : ""}
                {licenses
                  ? licenses.map((i, k) => {
                      return (
                        <div key={k} style={{ marginTop: 5, marginBottom: 5 }}>
                          <p>{i.license}</p>
                        </div>
                      );
                    })
                  : ""}
                {certifications ? <h2>CERTIFICATIONS</h2> : ""}
                {certifications
                  ? certifications.map((i, k) => {
                      return (
                        <div key={k}>
                          <p>{i.cert}</p>
                        </div>
                      );
                    })
                  : ""}
              </div>
            </div>
          </div>
          {education ? (
            <div className="member_about" role="region" aria-label="bio">
              <p>{about}</p>
            </div>
          ) : null}
        </Fragment>
      ))
    : "";

  return (
    <Fragment>
      {name ? (
        data
      ) : (
        <div
          style={{
            height: 500,
            width: "100%",
            marginTop: "20%",
            textAlign: "center",
            fontSize: "29px",
          }}
        >
          Comming Soon...
        </div>
      )}
    </Fragment>
  );
};

export default teamDetails;
