/* eslint-disable react/prop-types */
import React, { PureComponent, Fragment } from "react";

import { withRouter } from "react-router";
import Footer from "../../components/Footer";
import Navbar from "./Nav/aboutNavbar";
import Founder from "./founder";
import Team from "./team";
import History from "./history";
import TeamDetail from "./teamDetails";
//
import "./about.css";

class About extends PureComponent {
  constructor(props) {
    super(props);
    this.navHandler = this.navHandler.bind(this);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  navHandler(selectedKey) {
    if (!selectedKey && this.props && this.props.history) {
      this.props.history.push("/");
    } else {
      this.props.history ? this.props.history.push(`${selectedKey}`) : "";
    }
  }
  render() {
    const slugTeam = window.location.pathname.split("/")[3];
    const slug = window.location.pathname.split("/")[2];

    let content = <Whoweare />;

    if (slug === "founder") {
      content = <Founder />;
    }
    if (slug === "history") {
      content = <History />;
    }
    if (slug === "team" && typeof slugTeam === "undefined") {
      content = <Team />;
    }
    if (typeof slugTeam !== "undefined") {
      // console.log(slugTeam);
      content = <TeamDetail member={slugTeam} />;
    }

    return (
      <div className="pageContainer">
        <div className="about_container">
          <Navbar />
          {content}

          <div>
            <Footer navHandler={this.navHandler} />
          </div>
        </div>
      </div>
    );
  }
}

const Whoweare = () => {
  return (
    <Fragment>
      <header className="whoweare_hero">
        <img src="/images/company/WHO WE ARE - Banner - 1280x265.webp" alt="" />
        <div className="whoweare_hero-box">
          <h1>WHO WE ARE</h1>
          <h2>PROVIDING QUALITY SERVICES TO OUR CLIENTELE</h2>
        </div>
      </header>
      <main className="whoweare_text-box">
        <p className="whoweare_text-p2">
          The Belcastro Construction Group is a Disabled Veteran Business
          enterprise, committed to offering high-quality construction services
          helping our clients achieve elevated standards of construction. We are
          dedicated to providing optimal customer service and well-maintained
          equipment for upmost efficiency at your work site. Our organization
          offers quality management and timely performance on all types of
          projects. Our mission is to provide the Owners and General Contractors
          with a competitive, high quality, and timely completed projects while
          maintaining a productive and safe work environment for our employees
          and others on the project.
        </p>
        <p className="whoweare_text-p2 text-center">
          <a href="/privacy">Privacy Policy</a>
        </p>
      </main>
    </Fragment>
  );
};
export default withRouter(About);
