import React, { useState, useRef, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import myVideo from "./Container.mp4";

import "./style.css";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Card from "../../components/Card/card";
// import Spinner from "../../components/Spinner/Spinner";
import { motion } from "framer-motion";
import { Parallax } from "react-parallax";
import data from "./data";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
const Container = (props) => (
  <Parallax
    blur={{ min: -100, max: 0 }}
    bgImage={props && props.img ? props.img : ""}
    bgImageAlt="background image"
    strength={props && props.strength ? props.strength : 200}
  >
    {props.children}
    <div
      style={{ height: props.defaultHeight ? props.defaultHeight : "400px" }}
    />
  </Parallax>
);
///update
function CompanyPage(props) {
  const slug = window.location.pathname.split("/")[2];
  const company = data[slug] ? data[slug] : {}; //get company for this page

  const {
    bottomAnimation,
    horizontalScroller,
    horizontalScrollerText,
    loadingLogo,
    loadingTime,
    logo,
    parallaxImages,
    sectionOne = {},
    sectionTwo,
    sectionThree,
    sectionFour,
  } = company;
  const { scrollDownBackground } = sectionOne;
  const { ref, inView } = useInView(
    {
      /* Optional options */
      threshold: 0.65,

      // striggerOnce: true,
    }
    // (e) => {
    //   // console.log("e ", e);
    // }
  );
  const [loading, setLoading] = useState(true);
  const [leftPos, setLeftPos] = useState(0);

  const refHorizontal = useRef();
  const refInside = useRef();
  const topScrollRef = React.createRef();
  if (refHorizontal && refHorizontal.current)
    console.log("ref pos ", refHorizontal.current.offsetTop);

  const prevScrollY = useRef(0);
  const [goingUp, setGoingUp] = useState(false);

  const onScrollInner = (e) => {
    const currentScrollY = e.target.scrollTop;
    // console.log("inner y ", currentScrollY);

    if (currentScrollY > 0) {
      setLeftPos(-currentScrollY);
      // setTopPos(currentScrollY);
      // downscroll code

      setGoingUp(false);
    }
    if (prevScrollY.current > currentScrollY && !goingUp) {
      setGoingUp(true);
    }
    prevScrollY.current = currentScrollY;
    // console.log(goingUp, currentScrollY);
  };
  useEffect(() => {
    let loadingDelay = 4000;
    if (loadingTime) {
      loadingDelay = loadingTime * 1000 + 1500;
    }
    // console.log("curren ", topScrollRef);
    const timer = setTimeout(() => {
      // console.log("curren ", topScrollRef);
      window.scrollTo({
        top: 800,
        behavior: "smooth",
      });
    }, loadingDelay);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    let loadingDelay = 3000;
    if (loadingTime) {
      loadingDelay = loadingTime * 1000;
    }
    let timer = setTimeout(() => setLoading(false), loadingDelay);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  function navHandler(selectedKey) {
    // console.log("selected Key ", selectedKey);
    const { history } = props ? props : {};
    if (!selectedKey) {
      history ? history.push(`/`) : "";
    } else {
      history ? history.push(`${selectedKey}`) : "";
    }
  }
  if (loading) {
    return (
      <div className="companyContainer" style={{ background: "white" }}>
        <div className="loadingWrapper">
          <div className="loadingLogo">
            <img src={loadingLogo} alt="logo loading" />
          </div>
        </div>
        {/* <Spinner /> */}
      </div>
    );
  }

  if (!logo) {
    return <div style={{ marginTop: "200px" }}>Nothing to see here yet...</div>;
  } else {
    // console.log("inview: ", inView);
    return (
      <div>
        <div className="companyContainer">
          <header>
            {loading ? (
              <div className="loadingWrapper" role="alert" aria-busy="true">
                <div className="loadingLogo">
                  <img src={loadingLogo} alt="loading logo" />
                </div>
              </div>
            ) : (
              ""
            )}

            <Container
              img={parallaxImages[0]}
              strength={200}
              defaultHeight={800}
            >
              <a href="#main-content" style={{ color: "white" }}>
                Skip to Main Content
              </a>
              {/* <div className="companyTitle" >Ayard Rentals</div>    */}
            </Container>
            <div
              className="companyText"
              style={{ background: sectionOne.background }}
              ref={topScrollRef}
            >
              <div className="d-flex justify-content-center align-items-center">
                <div
                  style={{
                    width: "300px",
                    height: "2px",
                    background: "white",
                    margin: "15px",
                  }}
                ></div>
                <div className="logo">
                  <img src={logo} alt="logo" />
                </div>
                <div
                  style={{
                    width: "300px",
                    height: "2px",
                    background: "white",
                    margin: "15px",
                  }}
                ></div>
              </div>
              <div
                className="subTitle mt-5"
                style={{ color: sectionOne.color }}
              >
                {sectionOne.header}
              </div>

              <div
                style={
                  scrollDownBackground
                    ? {
                        width: "100%",
                        backgroundColor: scrollDownBackground,
                        height: "auto",

                        paddingBottom: "10px",
                      }
                    : {
                        width: "100%",

                        height: "auto",

                        paddingBottom: "10px",
                      }
                }
              >
                <div className="mt-5 scroll">
                  <div className="chevron"></div>
                  <div className="chevron"></div>
                  <div className="chevron"></div>
                </div>
                <div
                  className="text"
                  style={{
                    fontSize: "1.5rem",
                    color: "white",
                    overflow: "visible",
                  }}
                >
                  Scroll down
                </div>
              </div>
              <hr style={{ height: "3px" }} className="mx-5 mt-5" />
              <div className="row mt-5">
                <div className="col-5 offset-1 px-5 text-left">
                  <p
                    className="px-1"
                    style={{ color: sectionOne.color, fontSize: "2.5rem" }}
                  >
                    {sectionOne.paragraphLeft}
                  </p>
                </div>
                <div className="col-5 px-5 text-left">
                  <p
                    className="px-1"
                    style={{ color: sectionOne.color, fontSize: "2.5rem" }}
                  >
                    {sectionOne.paragraphRight}
                  </p>
                </div>
              </div>
              <Container
                img={parallaxImages[1]}
                strength={200}
                defaultHeight={640}
              />
            </div>
          </header>
          <main id="main-content">
            <div
              className="companyText"
              style={{
                // height: "1300px",
                backgroundColor: "white",
                color: "black",
                // overflowY: "scroll",
              }}
            >
              <h1 style={{ color: sectionTwo.headingColor }}>
                {sectionTwo.header}
              </h1>
              <div
                style={{
                  width: "80%",
                  marginLeft: "10%",
                  marginBottom: "50px",
                  fontSize: "18px",
                }}
              >
                {sectionTwo.paragraphs.map((item, key) => {
                  return (
                    <p key={key} style={{ fontSize: "1.5rem" }}>
                      {item}
                    </p>
                  );
                })}
              </div>
              <div className="row justify-content-md-center smallSection">
                {sectionTwo.grid
                  ? sectionTwo.grid.map((item) => {
                      const listItems = item.list
                        ? item.list.map((item) => (
                            <li key={`${item.title} li`}>{item}</li>
                          ))
                        : "";
                      return (
                        <div key={item.title} className="col-12 col-sm-3">
                          <div className="sectionTwo_image">
                            <img src={item.image} alt={item.title} />
                          </div>

                          <h2
                            style={{
                              color: "black",
                              textAlign: "center",
                              fontWeight: 600,
                            }}
                          >
                            {item.title}
                          </h2>
                          <p
                            style={{
                              color: "black",
                              textAlign: "left",
                              fontSize: "1.5rem",
                            }}
                          >
                            {item.paragraph}
                          </p>
                          {listItems}
                        </div>
                      );
                    })
                  : null}
              </div>
            </div>
            {/* <Container  strength={200}>
          <div className="companyTitle" style={{color:'black', height:200, width:200}}>blah blah</div>
      </Container>    */}
            {/* <div
            style={{
              backgroundColor: "rgb(0, 12, 45)",
              top: "79px",
              zIndex: 3,
              overflow: "hidden",
            }}
          >
            <img
              style={{ width: "100%" }}
              src="/images/ayard/AYARD - The difficult we do now - 1333x1000 (4x3).webp"
            />
          </div>

          <div
            style={{
              height: 200,
              width: "100%",
              background: "white",
            }}
          ></div> */}

            {sectionThree ? (
              <div className="sectionThree text-center">
                <h1>Projects</h1>
                <div className="sectionThree_grid">
                  {sectionThree.grid.map((item, key) => {
                    return (
                      <Card
                        key={key}
                        location={item.location}
                        img={item.image}
                      />
                    );
                  })}
                </div>
              </div>
            ) : sectionFour ? (
              <video controls autoPlay loop muted height={675} width="100%">
                <source
                  src={myVideo}
                  type="video/mp4"
                  alt="company video"
                ></source>
              </video>
            ) : (
              <div
                style={{
                  backgroundColor: "rgb(0, 12, 45)",
                  top: "79px",
                  zIndex: 3,
                  overflow: "hidden",
                }}
              >
                <img
                  style={{ width: "100%" }}
                  src="/images/ayard/AYARD - The difficult we do now - 1333x1000 (4x3).webp"
                  alt={
                    "slogan: The difficult we do now, the impossible takes a little longers"
                  }
                />
              </div>
            )}
            {/* HORIZONTAL SCROLLER */}
            {horizontalScroller ? (
              <div
                style={{
                  height: 200,
                  width: "100%",
                  background: "white",
                  margin: 0,
                  padding: 0,
                }}
              ></div>
            ) : (
              ""
            )}
            {horizontalScroller ? (
              <div
                style={{
                  height: 400,
                  position: "absolute",
                  marginBottom: -400,
                  left: leftPos,
                  background: "white",
                  padding: 0,
                  margin: 0,
                }}
                className="d-flex justify-content-start"
              >
                {horizontalScroller
                  ? horizontalScroller.map((item, i) => {
                      return (
                        <React.Fragment key={`${item.text}${i}`}>
                          <div key={`${item.imgage}${i}`}>
                            <img
                              style={{
                                width: "440px",
                                height: 400,
                              }}
                              src={item.image}
                              alt={item.text}
                            />
                          </div>
                          {/* <div
                          style={{
                            width: 200,
                            height: 400,
                            borderRight: "3px solid grey",
                            padding: "3px",
                          }}
                        >
                          <h5>{item.text}</h5>
                        </div> */}
                        </React.Fragment>
                      );
                    })
                  : ""}
              </div>
            ) : (
              <div></div>
            )}

            {horizontalScroller ? (
              <div
                ref={refHorizontal}
                onScroll={onScrollInner}
                style={{
                  height: 400,
                  width: "100%",
                  overflow: "hidden",
                  position: "relative",

                  overflowY: "scroll",
                }}
                className="border "
              >
                <div
                  ref={refInside}
                  style={{
                    height: 3500,
                    position: "absolute",
                    top: 0,
                    opacity: 0,
                    padding: 0,
                    margin: 0,
                  }}
                  className="d-flex justify-content-start "
                >
                  the scrolling div originals
                </div>
              </div>
            ) : (
              <div></div>
            )}

            {horizontalScroller && horizontalScrollerText ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{
                  fontSize: 28,
                  letterSpacing: "-1px",
                  background: "white",
                  textAlign: "center",
                  height: 100,
                }}
              >
                <div>{horizontalScrollerText}</div>
              </div>
            ) : (
              <div></div>
            )}

            {horizontalScroller ? (
              <div
                style={{
                  height: 100,
                  width: "100%",
                  background: "white",
                }}
              ></div>
            ) : (
              <div></div>
            )}

            <div ref={ref}>
              <Container
                img={parallaxImages[2]}
                strength={0}
                defaultHeight={900}
                bgClassName="bgClassName"
              ></Container>
            </div>
            <div
              className="bottomRiser"
              style={{ background: bottomAnimation.background }}
            >
              {console.log(
                "inview ",
                inView,
                "bottom animation ",
                bottomAnimation,
                "bottom",
                bottomAnimation.background
              )}
              {inView && bottomAnimation && bottomAnimation.background ? (
                <motion.div
                  animate={{
                    height: 520,
                    // background: bottomAnimation.background,  <<<------ ZACK - I WAS TRYING TO GET A VARIABLE THAT IS EDITABLE FOR THE BOTTOM ANIMATION COLOR - I AM NOT SURE THE DIFERENCE BETWEEN THESE TWO
                  }}
                  transition={{ ease: "easeOut", duration: 1, delay: 1 }}
                >
                  <div
                    style={{
                      height: 0,

                      zIndex: 100,
                      // background: bottomAnimation.background,  <<<------ ZACK - I WAS TRYING TO GET A VARIABLE THAT IS EDITABLE FOR THE BOTTOM ANIMATION COLOR - I AM NOT SURE THE DIFERENCE BETWEEN THESE TWO
                    }}
                    className="bottomAnimation"
                  >
                    <div className="bottomAnimation_title text-center">
                      {bottomAnimation.headingOne}
                    </div>
                    <div className="bottomAnimation_company text-center">
                      {bottomAnimation.headingTwo}
                    </div>
                    <div
                      style={{
                        border: "1px solid white",
                        width: "80%",
                        height: "2px",
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginTop: "20px",
                      }}
                    />
                    <div
                      style={{
                        width: "90%",
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginTop: "20px",
                        color: "white",
                      }}
                      className="row justify-content-md-center buttonWrapper "
                    >
                      {bottomAnimation.buttons.map((item) => {
                        return (
                          <div
                            key={item.textOne}
                            onClick={() => {
                              window.location.href = `${item.url}`;
                            }}
                          >
                            {item.textOne} <br />
                            {item.textTwo}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </motion.div>
              ) : (
                ""
              )}
            </div>
          </main>
          <footer className="globalNav">
            <Footer navHandler={navHandler} mobileScreen={true} />
            <Header />
            <div style={{ backgroundColor: "white", textAlign: "center" }}>
              © 2021 The Belcastro Group. All Rights Reserved.
              <br />
              <span className="legal-links">
                <a href="/privacy/">Privacy Policy</a> &nbsp;|&nbsp;{" "}
                <a href="/terms/">Terms of Use</a> &nbsp;|&nbsp;{" "}
                <a href="/ca-transparency/">CA Transparency Act</a>
              </span>
              <div style={{ height: 20, width: "100%" }}></div>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

CompanyPage.propTypes = {
  children: PropTypes.any,
  img: PropTypes.string,
};
Container.propTypes = {
  children: PropTypes.any,
  img: PropTypes.string,
  strength: PropTypes.number,
  defaultHeight: PropTypes.number,
};

export default withRouter(CompanyPage);
