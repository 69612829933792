import React from "react";
import PropTypes from "prop-types";
import { FiArrowRight } from "react-icons/fi";
import classes from "./MobileRow.module.css";

const MobileRow = (props) => {
  const { logo, image, link, name } = props;
  return (
    <div className={classes["mobile-row"]}>
      <img src={logo ? logo : ""} alt={name}></img>
      <img src={image ? image : ""} alt={"construction equipment"}></img>
      <div
        className={classes["mobile-link"]}
        onClick={() => props.navHandler(link ? link : "/")}
      >
        {name ? name : ""} <FiArrowRight />
      </div>
    </div>
  );
};
MobileRow.propTypes = {
  navHandler: PropTypes.func,
  logo: PropTypes.string,
  image: PropTypes.string,
  link: PropTypes.string,
  name: PropTypes.string,
};

export default MobileRow;
