import { Fragment, useEffect } from "react";
//
const Founder = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Fragment>
      <header role="banner" className="founder_title">
        <h3>OUR FOUNDER</h3>
        <h1 className="h1Test">PETER BELCASTRO</h1>
      </header>
      <div className="founder_content">
        <div className="founder_img-container">
          <img
            src="/images/team/HDSHT - Peter Belcastro - 600x600.webp"
            alt="Peter Balcastro founder"
          />
        </div>

        <div className="founder_text" role="main">
          <p>
            The Belcastro Group was founded by entrepreneur Peter Belcastro.
            Peter served as a member of the United States Naval Construction
            Force from 2007 to 2015. While in the service, he deployed to
            Africa, Afghanistan, and Europe where he served as the construction
            manager for several projects and received the Navy and Marine Corps
            Achievement Medal for each deployment. During his service he
            developed a rigorous attitude towards accomplishing tasks
            efficiently.
          </p>
          <p>
            After his career in the military, Peter utilized his construction
            knowledge and served as the project manager and superintendent from
            2014 thru 2017 on the $392 million United Airlines Terminal
            Renovation Project and $66 million LAX Westfield Terminal 2 Project.
            Peter then joined the construction team as the Lead Senior Project
            Manager for Delta Air Lines $1.78 billion Sky Way Project, where he
            helped oversee the day-to-day management of the project and was also
            responsible for the project closeout of Delta’s $270 million
            relocation project.
          </p>
          <p>
            When the opportunity arose, Peter applied for and received his
            certification as a DVBE business and began the journey we, here at
            The Belcastro Group, continue today.
          </p>
        </div>
      </div>
    </Fragment>
  );
};

export default Founder;
