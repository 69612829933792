import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import InfiniteCarousel from "react-leaf-carousel";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import MobileIndex from "./mobileIndex";
import Slide from "../../components/Slide";
import ReactGA from "react-ga";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
//write a component that extends PureComponent
const nextStyle = {
  position: "absolute",
  right: "0",
  top: "50%",
  transform: "translateY(-50%)",
  zIndex: "1000",
  cursor: "pointer",
  width: "50px",
  height: "50px",
  background: "transparent",
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "4px",
};
function NextArrow({ onClick }) {
  return (
    <div
      style={{
        position: "absolute",
        right: "0",
        top: "50%",
        transform: "translateY(-50%)",
        zIndex: "1000",
        cursor: "pointer",
        width: "50px",
        height: "50px",
        background: "transparent",
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "4px",
      }}
      className="vstack"
      role="button"
      type="button"
      onClick={(e) => {
        onClick(e);
      }}
      onKeyPress={(e) => {
        onClick(e);
      }}
      alt="Next button"
    >
      <div>
        <GrFormNext size={"4em"} color="#107FC9" style={{ color: "#107FC9" }} />
      </div>
      next
    </div>
  );
}
function PrevArrow({ onClick }) {
  return (
    <div
      style={{
        ...nextStyle,
        left: 0,
      }}
      role="button"
      type="button"
      onClick={(e) => {
        onClick(e);
      }}
      onKeyPress={(e) => {
        onClick(e);
      }}
      alt="Previous button"
      className="vstack"
    >
      <div>
        <GrFormPrevious
          size={"4em"}
          color="#107FC9"
          style={{ color: "blue" }}
        />
      </div>
      previous
    </div>
  );
}

// import Spinner from "../../components/Spinner/Spinner";
class HomePage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { loading: true };
    this.handleResize = this.handleResize.bind(this);
    this.navHandler = this.navHandler.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  componentDidMount() {
    ReactGA.pageview(window.location.pathname);
    this.timerHandle = setTimeout(() => this.setState({ loading: false }), 0);

    window.addEventListener("resize", this.handleResize);
    let height =
      this.divElement && this.divElement.clientHeight
        ? this.divElement.clientHeight
        : 360;
    const isMobile = window.innerWidth <= 768 ? true : false;
    height = window.innerWidth >= 1000 ? 648 : height;
    this.setState({ hoverCardHeight: height, isMobile });
  }
  componentWillUnmount() {
    // ***
    // Is our timer running?                 // ***
    if (this.timerHandle) {
      // ***
      // Yes, clear it                     // ***
      clearTimeout(this.timerHandle); // ***
      this.timerHandle = 0; // ***
    } // ***
  }

  handleResize() {
    let height =
      this.divElement && this.divElement.clientHeight
        ? this.divElement.clientHeight
        : 360;
    height = window.innerWidth >= 1000 ? 648 : height;
    this.setState({
      height: window.innerHeight,
      width: window.innerWidth,
      hoverCardHeight: height,
    });
  }

  hoverHandler() {}

  navHandler(selectedKey) {
    // console.log("selected Key ", selectedKey);
    if (!selectedKey) {
      this.props.history.push(`/`);
    } else {
      this.props.history.push(`${selectedKey}`);
    }
  }
  handleKeyPress(event, path) {
    if (event.key === "Enter" || event.key === " ") {
      if (this.navHandler) this.navHandler(path);
    }
  }

  render() {
    let { loading, height, width, hoverCardHeight, isMobile } = this.state; //forcing a rerender on window resize to prevent carousel bugs
    hoverCardHeight = hoverCardHeight / 2;
    // console.log("hover height ", hoverCardHeight);
    if (loading) {
      return (
        <div
          className="mainContainer loadingContainer"
          key={width + height}
          ref={(divElement) => {
            this.divElement = divElement;
          }}
          style={{ background: "white" }}
        >
          <div className="loadingLogo">
            <img
              src={`https://i.ibb.co/X7mrG9S/The-Belcastro-Group-logo-loader-Page-LAPTOP-G941-VM0-K.gif`}
              alt="loading"
            />
            {/* <Spinner /> */}
          </div>
        </div>
      );
    }
    if (!isMobile) {
      return (
        <div
          role="main"
          className="mainContainer"
          key={width + height}
          ref={(divElement) => {
            this.divElement = divElement;
          }}
        >
          <Header />
          <MainMenu
            navHandler={this.navHandler}
            handleKeyPress={this.handleKeyPress}
          />

          {hoverCardHeight ? (
            <InfiniteCarousel
              breakpoints={[
                {
                  breakpoint: 500,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                  },
                },
                {
                  breakpoint: 768,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                  },
                },
              ]}
              dots={false}
              showSides={false}
              sidesOpacity={0.5}
              sideSize={0.1}
              slidesToScroll={1}
              slidesToShow={4}
              scrollOnDevice={true}
              autoCycle={true}
              cycleInterval={4000}
              pauseOnHover={false}
              nextArrow={<NextArrow />}
              prevArrow={<PrevArrow />}
            >
              <Slide
                hoverCaption="hoverCardCaptionBottom"
                hoverCaptionClass="hoverCardCaptionBottomActive"
                hoverCaptionText="Ayard Rentals"
                imgUrls={[
                  "/images/home_scoller/HOMEPAGE - AYARD - gereen tie-in - 715x1430 (1x2).webp",
                  "/images/logos/LOGO - AYARD - 360x360.webp",
                  "/companies/ayard-rentals",
                ]}
                hoverCard="hoverCardTop"
                hoverClass="hoverCardActiveTop"
                hoverCardHeight={hoverCardHeight}
                id={"0"}
              />

              <Slide
                hoverCaption="hoverCardCaptionTop"
                hoverCaptionClass="hoverCardCaptionTopActive"
                hoverCaptionText="BC Managment"
                imgUrls={[
                  "/images/home_scoller/HOMEPAGE - BCM - operated equipment - 680x1360 (1x2).webp",
                  "/images/logos/LOGO - BCM honey comb - square - 320x320.webp",
                  "/companies/belcastro-construction-management",
                ]}
                hoverCard="hoverCardBottom"
                hoverClass="hoverCardActiveBottom"
                hoverCardHeight={hoverCardHeight}
                id={1}
              />

              <Slide
                hoverCaption="hoverCardCaptionBottom"
                hoverCaptionClass="hoverCardCaptionBottomActive"
                hoverCaptionText="CB Design"
                imgUrls={[
                  "/images/home_scoller/HOMEPAGE - cb-design - balsa - 680x1360 (1x2).webp",
                  "/images/logos/LOGO - CB-design - square - 320x320.webp",
                  "/companies/cb-design",
                ]}
                hoverCard="hoverCardTop"
                hoverClass="hoverCardActiveTop"
                hoverCardHeight={hoverCardHeight}
                id={2}
              />

              <Slide
                hoverCaption="hoverCardCaptionTop"
                hoverCaptionClass="hoverCardCaptionTopActive"
                hoverCaptionText="CB Logistics"
                imgUrls={[
                  "/images/home_scoller/HOMEPAGE - CB Logistics - Warehouse - Munchen - 720x1440 (1x2).webp",
                  "/images/logos/LOGO - CB Logistics - square - 360x360.webp",
                  "/companies/cb-logistics",
                ]}
                hoverCard="hoverCardBottom"
                hoverClass="hoverCardActiveBottom"
                hoverCardHeight={hoverCardHeight}
                id={3}
              />

              <Slide
                hoverCaption="hoverCardCaptionTop"
                hoverCaptionClass="hoverCardCaptionTopActive"
                hoverCaptionText="CB Wholesale"
                imgUrls={[
                  "/images/home_scoller/HOMEPAGE - CB Wholesale Electric - Lighting - 820x1640 (1to2).webp",
                  "/images/logos/LOGO - CB Wholesale - white background - square - 360x360.webp",
                  "/companies/cb-wholesale",
                ]}
                hoverCard="hoverCardBottom"
                hoverClass="hoverCardActiveBottom"
                hoverCardHeight={hoverCardHeight}
                id={4}
              />
            </InfiniteCarousel>
          ) : (
            ""
          )}

          <Footer navHandler={this.navHandler} />
        </div>
      );
    } else if (isMobile) {
      return <MobileIndex navHandler={this.navHandler} />;
    }
  }
}
HomePage.propTypes = {
  children: PropTypes.any,
  history: PropTypes.object,
};

function MainMenu(props) {
  const { handleKeyPress } = props;
  return (
    <div
      className="fade-in-hello d-none d-sm-inline"
      style={{
        position: "absolute",
        top: "60px",

        // background:'#476388',
        zIndex: 10000,
      }}
    >
      <div className="fade-in-text">
        <div style={{ marginTop: "35%", width: "100%" }}></div>
        <h1>
          The <br />
          Belcastro <br />
          Group
        </h1>
        <div style={{ height: 30, width: "100%" }}></div>
        <div
          role="button"
          type="button"
          className="navItem"
          onClick={() => props.navHandler("/companies/ayard-rentals")}
          onKeyPress={(e) => {
            handleKeyPress(e, "/companies/ayard-rentals");
          }}
        >
          AYARD Rentals
        </div>
        <div
          className="navItem"
          role="button"
          type="button"
          onClick={() =>
            props.navHandler("/companies/belcastro-construction-management")
          }
          onKeyPress={(e) => {
            handleKeyPress(e, "/companies/belcastro-construction-management");
          }}
        >
          Belcastro Construction Management
        </div>
        <div
          className="navItem"
          role="button"
          type="button"
          onClick={() => props.navHandler("/companies/cb-design")}
          onKeyPress={(e) => {
            handleKeyPress(e, "/companies/cb-design");
          }}
        >
          CB Design
        </div>
        <div
          className="navItem"
          role="button"
          type="button"
          onClick={() => props.navHandler("/companies/cb-logistics")}
          onKeyPress={(e) => {
            handleKeyPress(e, "/companies/cb-logistics");
          }}
        >
          CB Logistics
        </div>
        <div
          className="navItem"
          role="button"
          type="button"
          onClick={() => props.navHandler("/companies/cb-wholesale")}
          onKeyPress={(e) => {
            handleKeyPress(e, "/companies/cb-logistics");
          }}
        >
          CB Wholesale Electric
        </div>
      </div>
    </div>
  );
}
MainMenu.propTypes = {
  navHandler: PropTypes.func,
};
export default withRouter(HomePage);
